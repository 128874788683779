import React from "react";

import { getIn, useFormikContext } from "formik";

import {
  LivestockSalePricingTypeDisplayNameLkp,
  PricingTypes,
} from "constants/pricingTypes";

import { Input } from "./Input";

export const UnitPrice = ({
  disabled,
  autoFocus,
  name,
  pricingTypeField = "pricingTypeId",
  showLabel = true,
}) => {
  const formikContext = useFormikContext();

  const pricingTypeId = getIn(formikContext.values, pricingTypeField);

  const pricingTypeAttributesLookup = {
    [PricingTypes.PER_KILO]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.PER_KILO],
      afterSymbol: "¢",
    },
    [PricingTypes.GROSS]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.GROSS],
      beforeSymbol: "$",
    },
    [PricingTypes.PER_HEAD]: {
      label: LivestockSalePricingTypeDisplayNameLkp[PricingTypes.PER_HEAD],
      beforeSymbol: "$",
    },
  };

  const pricingTypeAttributes = pricingTypeAttributesLookup[pricingTypeId];

  return (
    <Input
      name={name}
      type="number"
      disabled={disabled}
      autoFocus={autoFocus}
      decimal
      {...pricingTypeAttributes}
      label={(showLabel && pricingTypeAttributes?.label) || "Price"}
    />
  );
};
