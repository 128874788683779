import queryString from "query-string";

import { BillingScreens } from "constants/billing";
import { ConsignmentModalSection, ModalTypes } from "constants/navigation";
import { SaleTypes } from "constants/sale";

import history from "appHistory";

import { getFullyQualifiedUrl } from "./fetch";

const SALE_URL_REGEX = /^\/saleyard\/([^/]+)\/(sale\/(\d+))?/;
let lastUrl;
let lastSaleId;
let lastSaleyard;

const extractParametersFromUrl = () => {
  const url = window.location.pathname;
  if (!lastUrl || url !== lastUrl) {
    lastUrl = url;
    const match = url.match(SALE_URL_REGEX);
    if (match) {
      lastSaleyard = unescape(match[1]);
      lastSaleId =
        match.length === 4 && match[3] ? parseInt(match[3], 10) : undefined;
    } else {
      lastSaleyard = undefined;
      lastSaleId = undefined;
    }
  }
};

export function getLivestockSaleId() {
  extractParametersFromUrl();
  return lastSaleId;
}

export function getSaleyardName() {
  extractParametersFromUrl();
  return lastSaleyard;
}

export function openHelpAndSupport() {
  window.open("https://support.agrinous.com.au/");
  return null;
}

export const supportPhoneNumber = "+61480 039 299";

export function openPhoneSupport() {
  window.open(`tel:${supportPhoneNumber}`);
  return null;
}

export function openClearHistoryCache() {
  window.open("https://support.agrinous.com.au/clear-history-cache", "_blank");
  return null;
}

export function openNewUserRequestForm() {
  window.open("https://share.hsforms.com/1IgZod6UJQgOa9ayrEYOu1Q312rl");
  return null;
}

export function openActivateIntegrationCredential(
  integrationCredentialId,
  userRoleSlug,
) {
  window.open(
    queryString.stringifyUrl({
      url: `/v2/integration-credentials/${integrationCredentialId}/activate`,
      query: {
        user_role: userRoleSlug,
      },
    }),
    "_self",
  );
}

export function openNewAuthBackend() {
  window.open("/accounts/login", "_self");
}

export function openSocialAccountManagement() {
  window.open("/accounts/social/connections", "_self");
}

export const getSaleRoute = (saleyardName, saleId) => {
  let url = `/saleyard/${saleyardName}`;
  if (saleId) {
    url = `${url}/sale/${saleId}`;
  }
  return url;
};

export const getSaleyardAuctionRoute = (
  saleYardName,
  saleId = null,
  roundId = null,
  auctionPendId = null,
) => {
  let url = getSaleRoute(saleYardName, saleId);
  if (roundId) {
    url = `${url}/round/${roundId}`;
  }
  if (auctionPendId) {
    url = `${url}/auctionPen/${auctionPendId}`;
  }
  return url;
};

export const getSaleEntryRoute = (
  saleYardName,
  saleId,
  saleType,
  route = null,
) => {
  let url = getSaleRoute(saleYardName, saleId);
  if (route) {
    url = `${url}/${route}`;
  } else if (saleType === SaleTypes.CLEARING) {
    url = `${url}/vendors`;
  } else if (saleType === SaleTypes.SUNDRY_SALE) {
    url = `${url}/billing/overview`;
  } else {
    url = `${url}/overview`;
  }
  return url;
};

export const getCurrentSaleyardAuctionRoute = (roundId, auctionPendId) => {
  return getSaleyardAuctionRoute(
    getSaleyardName(),
    getLivestockSaleId(),
    roundId,
    auctionPendId,
  );
};

export const getSaleUrl = ({ saleyard_name, livestocksale_id }) =>
  `/v2/saleyards/${saleyard_name}/sales/${livestocksale_id}`;

export const openSale = sale => {
  const url = getSaleyardAuctionRoute(
    sale.saleyard_name,
    sale.livestocksale_id,
  );
  history.push(url);
};

export const openSaleLotsTab = sale => {
  const url = getSaleyardAuctionRoute(
    sale.saleyard_name,
    sale.livestocksale_id,
  );
  history.push(`${url}/salelots`);
};

/**
 * Go to the base route of the current sale using the URL
 */
export const openCurrentSale = () =>
  history.push(`/saleyard/${getSaleyardName()}/sale/${getLivestockSaleId()}/`);

export const openCsvSaleImport = () => {
  history.push(
    `${getSaleRoute(
      getSaleyardName(),
      getLivestockSaleId(),
    )}/csv-presale-import`,
  );
};

export const openBusinessesInSale = () => {
  history.push(
    `${getSaleRoute(
      getSaleyardName(),
      getLivestockSaleId(),
    )}/businesses-in-sale`,
  );
};

export const openBusinessAlternatives = () => {
  history.push(
    `${getSaleRoute(
      getSaleyardName(),
      getLivestockSaleId(),
    )}/business-alternatives`,
  );
};

export const getAuctionPenCardsRoute = () =>
  `${getSaleyardAuctionRoute(
    getSaleyardName(),
    getLivestockSaleId(),
  )}/auction-pen-cards`;

export const openAuctionPenCardsRoute = () =>
  history.push(getAuctionPenCardsRoute());

export const getWatchRoute = sale => {
  const url = getSaleyardAuctionRoute(
    sale.saleyard_name,
    sale.livestocksale_id,
  );

  return `${url}/watch`;
};

const openBusinessModal = args => {
  history.push(
    `#${queryString.stringify(
      {
        [ModalTypes.EditBusiness]: JSON.stringify(args),
      },
      {
        arrayFormat: "comma",
        sort: false,
        encode: false,
      },
    )}`,
  );
};

export const goToBillingStep = (step = BillingScreens.OVERVIEW) => {
  const billingPath = `${getSaleRoute(
    getSaleyardName(),
    getLivestockSaleId(),
  )}/billing/${step}`;
  history.push(billingPath);
};

export const openNewBusinessModal = returnTo => {
  const args = {};
  if (returnTo) {
    args.returnTo = encodeURIComponent(returnTo);
  }
  openBusinessModal(args);
};

export const openEditBusinessModal = (
  businessId,
  returnTo = undefined,
  defaultTab = undefined,
  defaultAddressSearch = undefined,
) => {
  const args = {
    businessId,
  };
  if (returnTo) {
    args.returnTo = encodeURIComponent(returnTo);
  }
  if (defaultTab) {
    args.defaultTab = defaultTab;
  }
  if (defaultAddressSearch) {
    args.defaultAddressSearch = defaultAddressSearch;
  }
  openBusinessModal(args);
};

export const getManageBusinessRoute = () => {
  return "/app/businesses/";
};

export const getImportXmlRoute = sale => {
  const url = getSaleyardAuctionRoute(
    sale.saleyard_name,
    sale.livestocksale_id,
  );

  return `${url}/import`;
};

export const openImportXml = sale => {
  history.push(getImportXmlRoute(sale));
};

export const getImportAuctionsPlusCSVRoute = sale => {
  const url = getSaleyardAuctionRoute(
    sale.saleyard_name,
    sale.livestocksale_id,
  );

  return `${url}/ap-import`;
};

export const openImportAuctionsPlusCSV = sale => {
  history.push(getImportAuctionsPlusCSVRoute(sale));
};

export const getImportExternalAgentXmlRoute = sale => {
  const url = getSaleyardAuctionRoute(
    sale.saleyard_name,
    sale.livestocksale_id,
  );

  return `${url}/ex-agent-import`;
};

export const openImportExternalAgentXml = sale => {
  history.push(getImportExternalAgentXmlRoute(sale));
};

export const getSettingsRoute = tab => {
  const route = "/app/settings/";
  return tab ? `${route}${tab}` : route;
};

export const getUserOverviewRoute = () => "/app/";

export const getInboxRoute = () => `/app/inbox/`;

export function getMoneyTableRoute() {
  return `/app/money/`;
}

export function getReportJobList() {
  return `/app/report-jobs/`;
}

export const getReturnTo = () =>
  queryString.parse(window.location.search).returnTo;

export function getEditSaleLotHashRoute(
  saleLotId = "",
  section = "",
  sectionParams = "",
  consignmentId = "",
  copyFrom = "",
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.EditSaleLot]: JSON.stringify([
        saleLotId,
        section,
        sectionParams,
        consignmentId,
        copyFrom,
      ]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

function getCommentsHashRoute(
  commentType,
  commentTypeId,
  returnTo = "",
  options = {},
) {
  return queryString.stringify(
    {
      [ModalTypes.Comments]: JSON.stringify({
        returnTo: returnTo ? btoa(returnTo) : undefined,
        commentType,
        commentTypeId,
        options,
      }),
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openCommentsModal(
  commentType,
  commentTypeId,
  returnTo,
  options,
) {
  history.push(
    `#${getCommentsHashRoute(commentType, commentTypeId, returnTo, options)}`,
  );
}

function getEditUserRoleHashRoute(
  userRoleType,
  userRoleId,
  returnTo = "",
  options = {},
) {
  return queryString.stringify(
    {
      [ModalTypes.EditUserRole]: JSON.stringify({
        returnTo: returnTo ? btoa(returnTo) : undefined,
        userRoleType,
        userRoleId,
        options,
      }),
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openEditUserRoleModal(
  userRoleType,
  userRoleId,
  returnTo,
  options,
) {
  history.push(
    `#${getEditUserRoleHashRoute(userRoleType, userRoleId, returnTo, options)}`,
  );
}

export function getDeliverSaleLotHashRoute(saleLotId = "", returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.DeliverSaleLot]: JSON.stringify([saleLotId]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function getEmailReportHashRoute(
  reportUrl,
  reportName,
  businessId,
  deploymentId,
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.EmailReportModal]: JSON.stringify({
        reportUrl: btoa(reportUrl),
        reportName: btoa(reportName),
        businessId,
        deploymentId,
      }),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function getDeploymentSaleOptionsHashRoute(returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.DeploymentSaleOptions]: JSON.stringify(null),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export const openEditDeploymentSaleOptions = (returnTo = "") => {
  history.push(`#${getDeploymentSaleOptionsHashRoute(returnTo)}`);
};

export function getScanToPenHashRoute(returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.ScanToPen]: JSON.stringify([]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openScanToPenHashRoute(returnTo = "") {
  history.push(`#${getScanToPenHashRoute(returnTo)}`);
}

export function getAuditLogModalHashRoute(
  dataType,
  dataId,
  returnTo = "",
  queryParams = {},
) {
  return queryString.stringify(
    {
      [ModalTypes.AuditLog]: JSON.stringify([dataType, dataId, queryParams]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openAuditLogModal(dataType, dataId, returnTo) {
  history.push(`#${getAuditLogModalHashRoute(dataType, dataId, returnTo)}`);
}

export function getHashModalRoute({ modalType, queryArray, returnTo = "" }) {
  return queryString.stringify(
    {
      [modalType]: JSON.stringify(queryArray),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function getEditConsignmentHashRoute(
  consignmentId = "",
  section = ConsignmentModalSection.GENERAL,
  showSaleLots = false,
  fullScreen = false,
  returnTo = "",
  nominationId = null,
  agencyId = null,
  receivalLotId = null,
) {
  return queryString.stringify(
    {
      [ModalTypes.Consignment]: JSON.stringify([
        consignmentId,
        section,
        showSaleLots,
        fullScreen,
        nominationId,
        agencyId,
        receivalLotId,
      ]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function getSplitSaleLotHashRoute(
  saleLotId,
  splitType = "",
  fullScreen = false,
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.SplitSaleLot]: [saleLotId, splitType].join(","),
      fullScreen,
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function getEditSaleLotCarrierChargeHashRoute(
  saleLotId,
  fullScreen = false,
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.EditSaleLotCarrierCharge]: JSON.stringify([
        saleLotId,
        fullScreen,
      ]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function getConsignmentCarrierChargeHashRoute(
  consignmentId,
  fullScreen = false,
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.ConsignmentCarrierCharge]: JSON.stringify([
        consignmentId,
        fullScreen,
      ]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function getSendVendorReportsHashRoute(returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.SendVendorReports]: JSON.stringify([]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function getRuleTesterRoute(
  returnTo = "",
  livestockSaleIds,
  rulebookId,
  useContextRuleData,
) {
  return queryString.stringify(
    {
      [ModalTypes.RuleTester]: JSON.stringify([
        livestockSaleIds,
        rulebookId,
        useContextRuleData,
      ]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openRuleTester(
  returnTo,
  livestockSaleIds,
  rulebookId,
  useContextRuleData,
) {
  history.push(
    `#${getRuleTesterRoute(
      returnTo,
      livestockSaleIds,
      rulebookId,
      useContextRuleData,
    )}`,
  );
}

export function openSendVendorReportsModal(returnTo) {
  history.push(`#${getSendVendorReportsHashRoute(returnTo)}`);
}

export function getMergeSaleLotHashRoute(saleLotId, returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.MergeSaleLot]: saleLotId,
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openMergeLotsModal(saleLotId, returnTo) {
  history.push(`#${getMergeSaleLotHashRoute(saleLotId, returnTo)}`);
}

function getUpdateBuyerModal(
  buyerId,
  destinationPropertyId,
  buyerWayId,
  thirdPartyId,
  invoiceToBusinessId,
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.UpdateBuyer]: true,
      buyerId,
      destinationPropertyId,
      buyerWayId,
      thirdPartyId,
      invoiceToBusinessId,
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openUpdateBuyerModal(
  buyerId,
  destinationPropertyId,
  buyerWayid,
  thirdPartyId,
  invoiceToBusinessId,
  returnTo,
) {
  history.push(
    `#${getUpdateBuyerModal(
      buyerId,
      destinationPropertyId,
      buyerWayid,
      thirdPartyId,
      invoiceToBusinessId,
      returnTo,
    )}`,
  );
}

function getDeliveryPenOwnerModal(deliveryPenId, returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.DeliveryPenOwners]: true,
      deliveryPenId,
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openDeliveryPenOwnerModal(deliveryPenId, returnTo) {
  history.push(`#${getDeliveryPenOwnerModal(deliveryPenId, returnTo)}`);
}

export function openKillSheetHashModal(consignmentId, returnTo) {
  openModalLink(
    ModalTypes.KillSheet,
    {
      consignmentId,
    },
    returnTo,
  );
}

export function getMapBusinessHashRoute(searchHints, returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.MapBusiness]: JSON.stringify(searchHints),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: true,
    },
  );
}

export function openMapBusinessHashRoute(searchHints, returnTo = "") {
  history.push(`#${getMapBusinessHashRoute(searchHints, returnTo)}`);
}

export function getDeliveryPenHashRoute(saleLotIds, returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.DeliveryPen]: JSON.stringify(saleLotIds),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: true,
    },
  );
}

export function openDeliveryPenModal(saleLotIds, returnTo = "") {
  history.push(`#${getDeliveryPenHashRoute(saleLotIds, returnTo)}`);
}

export function getInlineCreateSaleLotModalRoute(
  initialValues,
  options,
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.InlineCreateSaleLot]: JSON.stringify({
        initialValues,
        options,
      }),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: true,
    },
  );
}

export function openInlineCreateSaleLotModal(
  initialValues,
  options,
  returnTo = "",
) {
  history.push(
    `#${getInlineCreateSaleLotModalRoute(initialValues, options, returnTo)}`,
  );
}

export function openSaleModal(saleId = null, returnTo = "") {
  const query = queryString.stringify(
    {
      [ModalTypes.Sale]: JSON.stringify([saleId]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
  history.push(`#${query}`);
}

export function openConsignToOtherSaleModal(returnTo = "") {
  const query = queryString.stringify(
    {
      [ModalTypes.ConsignToSale]: null,
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
  history.push(`#${query}`);
}

export function openEditScanningPenModal(
  sellingPenId,
  penScanLotId,
  penType,
  isBulkRePen,
  returnTo = "",
) {
  const query = queryString.stringify({
    [ModalTypes.EditScanningPen]: JSON.stringify({
      penId: sellingPenId,
      penScanLotId,
      penType,
      isBulkRePen,
    }),
    returnTo: returnTo ? btoa(returnTo) : undefined,
  });
  history.push(`#${query}`);
}

/**
 * Open ths Split Sale Lot modal. Provide a `returnTo` value to be navigated to
 * when the modal is closed/cancelled
 * @param {String} saleLotId the sale lot to split from
 * @param {SplitType=} splitType determines which category, price and buyer to
 * set the new lot to, where applicable.
 * @param {Boolean=} fullScreen displays the modal across the entire screen
 * @param {String=} returnTo the absolute or relative url to navigate to when
 * the modal is closed
 */
export function openSplitSaleLotModal(
  saleLotId,
  splitType,
  fullScreen,
  returnTo,
) {
  history.push(
    `#${getSplitSaleLotHashRoute(saleLotId, splitType, fullScreen, returnTo)}`,
  );
}

/**
 * Open ths Select Sale Lot Carrier Charge modal. Provide a `returnTo` value to
 * be navigated to when the modal is closed/cancelled
 * @param {String} saleLotId the sale lot to split from
 * @param {Boolean=} fullScreen displays the modal across the entire screen
 * @param {String=} returnTo the absolute or relative url to navigate to when
 * the modal is closed
 */
export function openEditSaleLotCarrierChargeModal(
  saleLotId,
  fullScreen,
  returnTo,
) {
  history.push(
    `#${getEditSaleLotCarrierChargeHashRoute(saleLotId, fullScreen, returnTo)}`,
  );
}

/**
 * Open ths Select Consignment Carrier Charge modal. Provide a `returnTo` value
 * to be navigated to when the modal is closed/cancelled
 * @param {String} consignmentId the sale lot to split from
 * @param {Boolean=} fullScreen displays the modal across the entire screen
 * @param {String=} returnTo the absolute or relative url to navigate to when
 * the modal is closed
 */
export function openConsignmentCarrierChargeModal(
  consignmentId,
  fullScreen,
  returnTo,
) {
  history.push(
    `#${getConsignmentCarrierChargeHashRoute(
      consignmentId,
      fullScreen,
      returnTo,
    )}`,
  );
}

export function openEditSaleLotModal(
  saleLotId,
  section,
  sectionAction,
  consignmentId,
  copyFrom,
  returnTo,
) {
  history.push(
    `#${getEditSaleLotHashRoute(
      saleLotId,
      section,
      sectionAction,
      consignmentId,
      copyFrom,
      returnTo,
    )}`,
  );
}

export function openDeliverSaleLotModal(saleLotId, returnTo) {
  history.push(`#${getDeliverSaleLotHashRoute(saleLotId, returnTo)}`);
}

export function openEmailReportModal(
  reportUrl,
  reportName,
  businessId,
  deploymentId,
  returnTo,
) {
  history.push(
    `#${getEmailReportHashRoute(
      reportUrl,
      reportName,
      businessId,
      deploymentId,
      returnTo,
    )}`,
  );
}

export function getEditBidderRegistrationHashRoute(
  bidderId,
  fullScreen = false,
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.EditBidder]: JSON.stringify([bidderId, fullScreen]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openEditBidderRegistrationModal(
  bidderId,
  fullScreen,
  returnTo,
) {
  history.push(
    `#${getEditBidderRegistrationHashRoute(bidderId, fullScreen, returnTo)}`,
  );
}

export function getBusinessPaymentsHashRoute(businessId, returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.BusinessPayments]: JSON.stringify([businessId]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openBusinessPaymentsModal(businessId, returnTo) {
  history.push(`#${getBusinessPaymentsHashRoute(businessId, returnTo)}`);
}

export function getScanRoute(
  consignmentId = "",
  saleLotId = "",
  resume = false,
  mode = null,
  returnTo = null,
  penArchetypeId = null,
  penId = null,
  scanLotId = null,
  penType,
) {
  return queryString.stringify(
    {
      [ModalTypes.Scan]: JSON.stringify({
        consignmentId,
        saleLotId,
        resume,
        mode,
        penArchetypeId,
        penId,
        scanLotId,
        penType,
      }),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openScanModal(
  consignmentId,
  saleLotId,
  resume,
  mode,
  penType,
  returnTo,
) {
  history.push(
    `#${getScanRoute(
      consignmentId,
      saleLotId,
      resume,
      mode,
      returnTo,
      null,
      null,
      null,
      penType,
    )}`,
  );
}

export function openPenScanning(
  penArchetypeId,
  penId,
  scanLotId,
  resume,
  mode,
  penType,
  returnTo = "",
) {
  history.push(
    `#${getScanRoute(
      null,
      null,
      resume,
      mode,
      returnTo,
      penArchetypeId,
      penId,
      scanLotId,
      penType,
    )}`,
  );
}

/**
 * Open ths Edit Consignment modal. Provide a `returnTo` value to be navigated to
 * when the modal is closed/cancelled
 * @param {String=} consignmentId the consignment to edit, or null if creating a new consignment
 * @param {Boolean=} showSaleLots determines if associated sale lots are displayed
 * @param {Boolean=} fullScreen displays the modal across the entire screen
 * @param {String=} returnTo the absolute or relative url to navigate to when
 * the modal is closed
 */
export function openEditConsignmentModal(
  consignmentId,
  section,
  showSaleLots,
  fullScreen,
  returnTo,
  nominationId,
  agencyId,
  receivalLotId,
) {
  history.push(
    `#${getEditConsignmentHashRoute(
      consignmentId,
      section,
      showSaleLots,
      fullScreen,
      returnTo,
      nominationId,
      agencyId,
      receivalLotId,
    )}`,
  );
}

export function closeAllHashModals() {
  history.push("#");
}

export function returnToLocation(location) {
  history.push(location);
}

export const getDashboardRoute = () => "/dashboard/";

export const openAnimalModal = (baseUrl, eid, returnTo) => {
  history.push(
    `${baseUrl}/animal/${eid}${returnTo ? `?returnTo=${returnTo}` : ""}`,
  );
};

export const getSingleWeighRoute = (saleyardName, saleId) =>
  `${getSaleRoute(saleyardName, saleId)}/single-weigh`;

export const getSingleWeighControlsRoute = (
  saleyardName,
  saleId,
  singleWeighId,
) => `${getSingleWeighRoute(saleyardName, saleId)}/${singleWeighId}`;

export const openSingleWeighControls = (
  saleyardName,
  saleId,
  singleWeighId,
) => {
  history.push(
    getSingleWeighControlsRoute(saleyardName, saleId, singleWeighId),
  );
};

export function getEditPaymentHashRoute(
  paymentId,
  options = null,
  returnTo = window.location.hash,
) {
  return queryString.stringify(
    {
      [ModalTypes.EditPayment]: JSON.stringify([paymentId, options]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

/**
 * Opens the Edit Payment Modal
 * When a paymentId is specified the modal will open is edit mode.
 * When the paymentId is set to `null` the model will open is create mode.
 *
 * @param {string|null} paymentId
 * @param {Object} [options]
 * @param {Payment} [options.presets]
 * @param {string} returnTo
 */
export function openEditPaymentModal(paymentId, options, returnTo) {
  history.push(`#${getEditPaymentHashRoute(paymentId, options, returnTo)}`);
}

export const getClearingSaleBuyerInvoiceUrl = (businessId, userRoleSlug) => {
  return queryString.stringifyUrl({
    url: `/v2/clearing-sale/${getLivestockSaleId()}/buyer/${businessId}/buyer-invoice/`,
    query: { user_role: userRoleSlug, static: true, async: true },
  });
};

export const getBillingRunBuyerInvoiceUrl = (
  livestockSaleId,
  businessId,
  userRoleSlug,
) => {
  return queryString.stringifyUrl({
    url: `/v2/clearing-sale/${livestockSaleId}/buyer/${businessId}/billing-run-buyer-invoice/`,
    query: { user_role: userRoleSlug, static: true, async: true },
  });
};

export const getBillingRunBuyerReceiptUrl = (
  livestockSaleId,
  businessId,
  userRoleSlug,
) => {
  return queryString.stringifyUrl({
    url: `/v2/clearing-sale/${livestockSaleId}/buyer/${businessId}/billing-run-buyer-receipt/`,
    query: { user_role: userRoleSlug, static: true, async: true },
  });
};

export const getBillingRunVendorInvoiceUrl = (
  livestockSaleId,
  businessId,
  userRoleSlug,
) => {
  return queryString.stringifyUrl({
    url: `/v2/clearing-sale/${livestockSaleId}/vendor/${businessId}/billing-run-vendor-invoice/`,
    query: { user_role: userRoleSlug, static: true, async: true },
  });
};

export const getClearingSaleBuyerReceiptUrl = (businessId, userRoleSlug) => {
  return queryString.stringifyUrl({
    url: `/v2/clearing-sale/${getLivestockSaleId()}/buyer/${businessId}/buyer-receipt/`,
    query: { user_role: userRoleSlug, static: true, async: true },
  });
};

export const getClearingSaleRecipientCreatedTaxInvoiceUrl = (
  vendorId,
  userRoleSlug,
) => {
  return queryString.stringifyUrl({
    url: `/v2/clearing-sale/${getLivestockSaleId()}/vendor/${vendorId}/vendor-invoice/`,
    query: { user_role: userRoleSlug, static: true, async: true },
  });
};

function getSingleWeighSettingsHashRoute(
  singleWeighId,
  options = null,
  returnTo = window.location.hash,
) {
  return queryString.stringify(
    {
      [ModalTypes.SingleWeighSettings]: JSON.stringify([
        singleWeighId,
        options,
      ]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openEditSingleWaySettingsModal(
  singleWeighId,
  options,
  returnTo,
) {
  history.push(
    `#${getSingleWeighSettingsHashRoute(singleWeighId, options, returnTo)}`,
  );
}

function getEditNominationHashRoute(
  nominationId,
  options = null,
  returnTo = window.location.hash,
) {
  return queryString.stringify(
    {
      [ModalTypes.EditNomination]: JSON.stringify([nominationId, options]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openEditNominationModal(nominationId, options, returnTo) {
  history.push(
    `#${getEditNominationHashRoute(nominationId, options, returnTo)}`,
  );
}

function getEditCheckpointHashRoute(
  checkpointId,
  options = null,
  returnTo = window.location.hash,
) {
  return queryString.stringify(
    {
      [ModalTypes.EditCheckpoint]: JSON.stringify([checkpointId, options]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openEditCheckpointModal(checkpointId, options, returnTo) {
  history.push(
    `#${getEditCheckpointHashRoute(checkpointId, options, returnTo)}`,
  );
}

export function openConsignmentsTable(
  saleyardName = getSaleyardName(),
  livestockSaleId = getLivestockSaleId(),
) {
  history.push(`${getSaleRoute(saleyardName, livestockSaleId)}/consignments`);
}

export function openOverview(
  saleyardName = getSaleyardName(),
  livestockSaleId = getLivestockSaleId(),
) {
  history.push(`${getSaleRoute(saleyardName, livestockSaleId)}/overview`);
}

export function openBuyerCards(
  saleyardName = getSaleyardName(),
  livestockSaleId = getLivestockSaleId(),
) {
  history.push(`${getSaleRoute(saleyardName, livestockSaleId)}/buyer-cards`);
}

export const isOnWeighBridge = () =>
  window.location.pathname.includes("weighbridge");

export const openAttachments = query => {
  const saleyard = getSaleyardName();
  const saleId = getLivestockSaleId();

  let url = `${getSaleyardAuctionRoute(saleyard, saleId)}/attachments`;
  query && (url = `${url}?${queryString.stringify(query)}`);

  history.push(url);
};

export const openNLISTransferReceipt = (id, transferType) => {
  const params = {
    modalType: ModalTypes.NLISTransferReceipt,
    queryArray: [id, transferType],
  };

  history.push(`#${getHashModalRoute(params)}`);
};

export function getEditIntegrationBusinessHashRoute(
  integrationCredentialType,
  businessId,
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.EditIntegrationBusiness]: JSON.stringify([
        integrationCredentialType,
        businessId,
      ]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openEditIntegrationBusinessModal(
  integrationCredentialType,
  businessId,
  returnTo,
) {
  history.push(
    `#${getEditIntegrationBusinessHashRoute(
      integrationCredentialType,
      businessId,
      returnTo,
    )}`,
  );
}

export function getCreateIntegrationBusinessHashRoute(
  integrationCredentialType,
  businessId,
  returnTo = "",
) {
  return queryString.stringify(
    {
      [ModalTypes.CreateIntegrationBusiness]: JSON.stringify([
        integrationCredentialType,
        businessId,
      ]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openCreateIntegrationBusinessModal(
  integrationCredentialType,
  businessId,
  returnTo,
) {
  history.push(
    `#${getCreateIntegrationBusinessHashRoute(
      integrationCredentialType,
      businessId,
      returnTo,
    )}`,
  );
}

export function openIntegrationSettings() {
  history.push(getSettingsRoute("Integrations"));
}

export function getManualAdjustmentFormModal(dataType, dataId, returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.AuditLog]: JSON.stringify([dataType, dataId]),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function getModalLink(
  modalType,
  modalProps = {},
  returnTo = window.location.hash,
) {
  const link = queryString.stringify(
    {
      [modalType]: encodeURIComponent(JSON.stringify(modalProps)),
      returnTo: returnTo ? btoa(returnTo) : undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
  return `#${link}`;
}

export function openModalLink(modalType, modalProps = {}, returnToParam) {
  let returnTo = returnToParam;
  if (typeof returnTo === "object" && returnTo !== null) {
    if (returnToParam.keepExistingReturnTo) {
      returnTo = getReturnTo() || "";
    }
  } else if (returnToParam === undefined) {
    returnTo = window.location.hash;
  }
  history.push(getModalLink(modalType, modalProps, returnTo));
}

export function getSelectSyncDataHashRoute(businessId, returnTo = "") {
  return queryString.stringify(
    {
      [ModalTypes.SelectSyncData]: JSON.stringify([businessId]),
      returnTo: returnTo || undefined,
    },
    {
      arrayFormat: "comma",
      sort: false,
      encode: false,
    },
  );
}

export function openSelectSyncDataModal(businessId, returnTo) {
  history.push(`#${getSelectSyncDataHashRoute(businessId, returnTo)}`);
}

export const getNominationCardsRoute = (saleyardName, saleId) => {
  return `${getSaleRoute(saleyardName, saleId)}/nomination-cards`;
};

export const getNominationTableRoute = (saleyardName, saleId) => {
  return `${getSaleRoute(saleyardName, saleId)}/nominations`;
};

export function getLedgerEntriesTableRoute(saleyardName, saleId, billingRunId) {
  return `${getSaleRoute(
    saleyardName,
    saleId,
  )}/billing/billing-runs/${billingRunId}`;
}

export function openLedgerEntriesTable(saleyardName, saleId, billingRunId) {
  history.push(getLedgerEntriesTableRoute(saleyardName, saleId, billingRunId));
}

export const reloadApplication = () => {
  window.stop();
  window.location.href = `/?${new Date().getTime()}`;
};

export const redirectToFirstSaleyardFromRole = auth => {
  const saleyardName = auth.activeRole.saleyards[0].name;
  const date = new Date();
  history.push(`${getSaleyardAuctionRoute(saleyardName)}/?${date.getTime()}`);
};

export const getAccountSaleReportUrl = userRoleSlug => {
  const livestockSaleId = getLivestockSaleId();
  const saleyardName = getSaleyardName();
  return `/reports/saleyards/${saleyardName}/account-sale/${livestockSaleId}/?user_role=${userRoleSlug}`;
};

export const getTaxInvoiceUrl = userRoleSlug => {
  const livestockSaleId = getLivestockSaleId();
  const saleyardName = getSaleyardName();
  return `/reports/saleyards/${saleyardName}/finance-invoice/${livestockSaleId}/?user_role=${userRoleSlug}`;
};

export const getBillingDocumentPreviewUrl = (
  userRoleSlug,
  billingDocumentId,
) => {
  const livestockSaleId = getLivestockSaleId();
  const saleyardName = getSaleyardName();

  return `/reports/saleyards/${saleyardName}/finance-report/${livestockSaleId}/${billingDocumentId}/?user_role=${userRoleSlug}&show_draft=True`;
};

export const getReportJobUrl = (reportJobId, userRoleSlug) => {
  if (!reportJobId || !userRoleSlug) {
    return null;
  } else {
    return getFullyQualifiedUrl(
      `/v2/report-jobs/${reportJobId}/?user_role=${userRoleSlug}`,
    );
  }
};

export const getBillingSubPageUrl = subSection =>
  `${getSaleRoute(
    getSaleyardName(),
    getLivestockSaleId(),
  )}/billing/${subSection}`;

export const getPenScanLotCardsRoute = (saleyardName, saleId) => {
  return `${getSaleRoute(saleyardName, saleId)}/pen-scanning-cards`;
};

export const getPenScanLotTableRoute = (saleyardName, saleId) => {
  return `${getSaleRoute(saleyardName, saleId)}/pen-scanning`;
};

export const getReceivalScanLotCardsRoute = (saleyardName, saleId) => {
  return `${getSaleRoute(saleyardName, saleId)}/arrival-scanning-cards`;
};

export const getReceivalScanLotTableRoute = (saleyardName, saleId) => {
  return `${getSaleRoute(saleyardName, saleId)}/arrival-scanning`;
};

export const openReceivalLotModal = (
  receivalLotId,
  newPenName,
  consignmentId,
) => {
  openModalLink(ModalTypes.EditReceivalLot, {
    receivalLotId,
    newPenName,
    consignmentId,
  });
};

export const openLedgerEntryDetail = () => {
  history.push(
    `${getSaleRoute(
      getSaleyardName(),
      getLivestockSaleId(),
    )}/billing/ledger-entry-detail`,
  );
};

export const getTransactionListUrl = ({
  startDate,
  endDate,
  masterBusinessId,
  userRole,
}) =>
  `/reports/transaction-list/?livestocksale-date__gte=${startDate}&livestocksale-date__lte=${endDate}&business_id=${masterBusinessId}&user_role=${userRole.slug}`;

export const getFinancialStatementReportUrl = ({
  startDate,
  endDate,
  masterBusinessId,
  userRole,
}) =>
  `/reports/financial-statement/?start_date=${startDate}&end_date=${endDate}&business_id=${masterBusinessId}&user_role=${userRole.slug}&static=1&async=1`;

export const getFinancialStatementZipReportUrl = ({
  startDate,
  endDate,
  masterBusinessId,
  userRole,
}) =>
  `/reports/financial-statement-zip/?start_date=${startDate}&end_date=${endDate}&business_id=${masterBusinessId}&user_role=${userRole.slug}&static=1&async=1`;

export const openReportJobsList = () => {
  history.push(getReportJobList());
};
