import React from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, getIn, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import {
  ManualAdjustmentAction,
  openDispatchingModal,
  setModalContext,
} from "actions";

import { PricingMethod } from "components/Billing/LedgerEntry/LedgerEntryPricingForm";
import { EditButton } from "components/Button";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import { ManualChargeLabelSelectorField } from "components/Form/Fields";
import { TextArea } from "components/Form/FormikControls";
import { FormikAwareBusinessPICSelector } from "components/Form/FormikControls/BusinessPICSelector";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { SundryPricingForm } from "components/Sundry/Form/SundryPricingForm";
import { SmallText } from "components/Text";

import { GstMethod } from "constants/billing";
import {
  BUYER,
  DEPLOYMENT,
  LIVESTOCK_AGENT,
  TRANSPORTER,
  VENDOR,
} from "constants/businesses";
import { BusinessModalSection, ModalTypes } from "constants/navigation";

import { EMPTY_OBJECT, shallowObjectChanges } from "lib";

import { getLivestockSaleId, openModalLink } from "lib/navigation";

import {
  getActiveLivestockAgentDeployment,
  getContextByModalType,
  getIsLivestockAgent,
  getManualAdjustments,
  getShouldChargeGSTByBusinessId,
} from "selectors";

import { useBoolean } from "hooks";

const businessRoles = [BUYER, DEPLOYMENT, LIVESTOCK_AGENT, TRANSPORTER, VENDOR];

const validationSchema = Yup.object().shape({
  toBusinessId: Yup.string()
    .nullable()
    .required("Required")
    .test("valid", "Required", val => val !== "-1"),
  fromBusinessId: Yup.string()
    .nullable()
    .required("Required")
    .test("valid", "Required", val => val !== "-1"),
  note: Yup.string().required("Required"),
});

const isGstApplicableMap = {
  true: "Applicable",
  false: "Not Applicable",
  undefined: "Unknown",
  null: "Unknown",
};

function SundryForm(props) {
  const { id, onClose, readOnlyFields = [], namespace: ns = "" } = props;

  const dispatch = useDispatch();
  const { values } = useFormikContext();
  const fieldValues = getIn(values, ns);

  const { fromBusinessId, toBusinessId } = fieldValues;
  const [isShowingConfirmDelete, showConfirmDelete, hideConfirmDelete] =
    useBoolean(false);

  const shouldFromBusinessChargeGST = useSelector(
    getShouldChargeGSTByBusinessId(fromBusinessId),
  );

  const fromBusinessGSTStatus = isGstApplicableMap[shouldFromBusinessChargeGST];

  const onConfirmDelete = () => {
    id && dispatch(ManualAdjustmentAction.delete(id));
    onClose();
  };

  const onViewAll = () => {
    openModalLink(ModalTypes.ManualAdjustmentTable);
  };

  const returnTo = window.location.hash;

  function openEditFromBusiness() {
    dispatch(
      openDispatchingModal(
        ModalTypes.EditBusiness,
        returnTo,
        {
          fromBusinessId,
          toBusinessId,
        },
        {
          businessId: fromBusinessId,
          defaultTab: BusinessModalSection.FINANCE_AND_ACCOUNTING,
        },
      ),
    );
  }

  function openEditToBusiness() {
    dispatch(
      openDispatchingModal(
        ModalTypes.EditBusiness,
        returnTo,
        {
          fromBusinessId,
          toBusinessId,
        },
        {
          businessId: toBusinessId,
          defaultTab: BusinessModalSection.FINANCE_AND_ACCOUNTING,
        },
      ),
    );
  }

  return (
    <Form>
      <DialogTitle onClose={onClose}>Sundry</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ManualChargeLabelSelectorField
              label="Labels"
              name="labels"
              disabled={readOnlyFields.includes("labels")}
            />
          </Grid>

          <Grid item xs={10}>
            <FormikAwareBusinessPICSelector
              allowBusinessOnly
              businessRoles={businessRoles}
              label="Billed From"
              businessFieldName="fromBusinessId"
              disabled={readOnlyFields.includes("fromBusinessId")}
            />
            <SmallText>GST Status - {fromBusinessGSTStatus}</SmallText>
          </Grid>
          <Grid
            item
            xs={2}
            container
            justifyContent="center"
            alignItems="center"
          >
            <EditButton
              onClick={openEditFromBusiness}
              disabled={!fromBusinessId}
            />
          </Grid>

          <Grid item xs={10}>
            <FormikAwareBusinessPICSelector
              allowBusinessOnly
              businessRoles={businessRoles}
              label="Billed To"
              businessFieldName="toBusinessId"
              disabled={readOnlyFields.includes("toBusinessId")}
            />
          </Grid>
          <Grid
            item
            xs={2}
            container
            justifyContent="center"
            alignItems="center"
          >
            <EditButton onClick={openEditToBusiness} disabled={!toBusinessId} />
          </Grid>

          <SundryPricingForm readOnlyFields={readOnlyFields} />
          <Grid item xs={12}>
            <TextArea
              label="Note"
              name="note"
              readOnly={readOnlyFields.includes("note")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {id && (
          <DeleteButton type="button" onClick={showConfirmDelete}>
            Delete
          </DeleteButton>
        )}
        <SecondaryButton type="button" onClick={onViewAll}>
          View All
        </SecondaryButton>

        <SecondaryButton type="button" onClick={onClose}>
          Cancel
        </SecondaryButton>
        <Button data-tour="adjust" type="submit">
          Submit
        </Button>
      </DialogActions>
      <ConfirmDialog
        title={createModalTitle("this Sundry")}
        isOpen={isShowingConfirmDelete}
        onCancel={hideConfirmDelete}
        onDelete={onConfirmDelete}
      />
    </Form>
  );
}

export const Modal = ({
  id,
  fromBusinessId,
  toBusinessId,
  labels,
  gstMethod,
  readOnlyFields,
  note = "",
  totalAmountCents = undefined,
  onClose,
}) => {
  const defaults = {
    fromBusinessId,
    toBusinessId,
    labels,
    note,
    totalAmountCents,
    gstMethod: gstMethod || GstMethod.GST_INCLUSIVE,
    pricingMethod: PricingMethod.GROSS_PRICE,
  };

  const handleClose = () => {
    dispatch(setModalContext(ModalTypes.EditBusiness, null));
    onClose();
  };

  const manualAdjustment =
    useSelector(getManualAdjustments)?.[id] || EMPTY_OBJECT;

  const context =
    useSelector(getContextByModalType(ModalTypes.EditBusiness)) || {};

  const initialValues = {
    ...defaults,
    // Variable names danced so that we can make use shared common in usePricingFormContext.
    taxAmount: manualAdjustment.gstCents,
    subtotal: manualAdjustment.subtotalCents,
    totalInc: manualAdjustment.totalCents,
    ...manualAdjustment,
    ...context,
  };

  const deploymentId = useSelector(state =>
    getIsLivestockAgent(state)
      ? getActiveLivestockAgentDeployment(state).id
      : null,
  );

  const dispatch = useDispatch();

  const onSubmit = rawValues => {
    // Variable names danced so that we can make use shared common in usePricingFormContext.
    const values = {
      fromBusinessId: rawValues.fromBusinessId,
      toBusinessId: rawValues.toBusinessId,
      note: rawValues.note,
      subtotalCents: rawValues.subtotal,
      gstMethod: rawValues.gstMethod,
      gstCents: rawValues.taxAmount,
      labels: rawValues.labels,
    };

    if (id) {
      const payload = {
        id,
        ...shallowObjectChanges(values, manualAdjustment),
      };

      dispatch(ManualAdjustmentAction.update(payload));
    } else {
      const payload = {
        ...values,
        id: uuidv4(),
        livestockSaleId: getLivestockSaleId(),
        deploymentId,
      };
      dispatch(ManualAdjustmentAction.create(payload));
    }
    handleClose();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <ZoomyDialog open onClose={handleClose}>
        <SundryForm
          id={id}
          readOnlyFields={readOnlyFields}
          onClose={handleClose}
        />
      </ZoomyDialog>
    </Formik>
  );
};
