import React from "react";

import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import EditReceivalLotModalAdapter from "components/ArrivalScanning/ReceivalLot/Modal/Adapter";
import { ReceivalLotsTable } from "components/ArrivalScanning/Table";
import { ArrivalScanningView } from "components/ArrivalScanning/View";
import { FeatureRoute } from "components/Authorization/FeatureRoute";
import SecurityTrimmedRoute from "components/Authorization/SecurityTrimmedRoute";
import { BillingWorkFlow } from "components/BillingWorkFlow";
import { LedgerEntryDetail } from "components/BillingWorkFlow/LedgerEntryDetail";
import ResolveAlternatives from "components/BusinessesInSale/ResolveAlternatives";
import { BusinessesInSaleTable } from "components/BusinessesInSale/Table";
import { BusinessPaymentsModalAdapter } from "components/BusinessPaymentsModal";
import { EditCheckpointModalAdapter } from "components/Checkpoints/Modal";
import { Buyers, Vendors } from "components/ClearingSaleCards";
import { Catalogue } from "components/ClearingSaleCards/Catalogue";
import CommentsModalAdapter from "components/Comments/Modal/adapter";
import ConsignmentCardsView from "components/ConsignmentCardsView";
import ConsignmentsTable from "components/ConsignmentsTable";
import DeliverSaleLotModalAdapter from "components/DeliverSaleLotModal/Adapter";
import DeliveryPenModalAdapter from "components/DeliveryPenModal/Adapter";
import DeliveryPenOwnersModalAdapter from "components/DeliveryPens/Modal/adapter";
import { DuplicateBusinessModal } from "components/DuplicatesBusinessModal";
import { EContractView } from "components/EContracts";
import EditBidderModalAdapter from "components/EditBidderModal/Adapter";
import { EditPaymentModalAdapter } from "components/EditPaymentModal";
import EditScanningPenModalAdapter from "components/EditScanningPenModal/Adapter";
import EmailReportModalAdapter from "components/EmailReportModal/adapter";
import HashRoute from "components/HashRoute";
import {
  AuctionsPlusImporter,
  ExternalAgentXmlImporter,
  PreSaleCsvImporter,
  SG8Importer,
} from "components/Importer";
import InlineCreateSaleLotModalAdapter from "components/InlineCreateSaleLotModal/adapter";
import KillSheetModal from "components/KillSheet";
import MergeSaleLotModal from "components/MergeSaleLotModal/Adapter";
import DispatchingHashModalAdapter from "components/Modal/DispatchingAdapter";
import { NominationCards } from "components/Nominations/Cards";
import { NominationTable } from "components/Nominations/Table";
import {
  LotSheet,
  RenderA3PlacardV3,
  RenderA4PlacardV3,
  RenderPlacardV1,
  RenderPlacardV2,
  RenderPlacardV4,
  RenderPlacardV5,
  SheepPlacard,
} from "components/Pdfs";
import { SheepPenStoreCard } from "components/Pdfs/SheepPenStoreCard";
import { AllocatePenScanLotsModal } from "components/PenScanning/AllocationModal";
import EditPenScanLotModalAdapter from "components/PenScanning/PenScanLot/Modal/Adapter";
import { PenScanLotsTable } from "components/PenScanning/Table";
import { PenScanningView } from "components/PenScanning/View";
import { HooksSale, PaddockSale } from "components/PrivateSales";
import PrivateSaleSaleLotsTable from "components/PrivateSales/SaleLotsTable";
import { SaleLotModalAdapter } from "components/SaleLotForms/Adapter";
import SaleLotsTable from "components/SaleLotsTable";
import { SaleWatcher } from "components/SaleWatcher";
import Scan from "components/ScanningScreen/Adapter";
import ScanTable from "components/ScanTable";
import ScanToPenModalAdapter from "components/ScanToPenModal/Adapter";
import {
  ConsignmentCarrierChargeModalAdapter,
  SaleLotCarrierChargeModalAdapter,
} from "components/SelectCarrierChargeModal";
import { SendVendorReportsModal } from "components/SendVendorReportsModal";
import SplitSaleLotModal from "components/SplitLotModal/Adapter";
import { SundryModalApapter } from "components/Sundry/Form/Adapter";
import { ManualAdjustmentTableModal } from "components/Sundry/Table";
import UpdateBuyerModalAdapter from "components/UpdateBuyerModal/adapter";
import { UpdateDefaultPropertyModal } from "components/UpdateDefaultPropertyModal";
import VendorSplitConsignmentModalAdapter from "components/VendorSplits/VendorSplitConsignmentModal/adapter";
import VendorSplitSaleLotModalAdapter from "components/VendorSplits/VendorSplitSaleLotModal/adapter";
import { ImportWeighLotsModal } from "components/WeighLots/Modal";
import { WeighScanLotsTable } from "components/WeighScanning";
import { WeightSettingsModal } from "components/WeighScanning/SettingsModal";

import { ModalTypes } from "constants/navigation";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { SaleTypes } from "constants/sale";

import AuctionPenCardsView from "containers/AuctionPenCardsView";
import AuctionPenSale from "containers/AuctionPenSale";
import BuyerCardsView from "containers/BuyerCardsView";
import EditConsignmentModal from "containers/Consignment/Adapter";
import Attachments from "containers/Dashboard/Attachments";
import { ExternalAgentSaleOverview } from "containers/Dashboard/ExternalAgentSaleOverview";
import Overview from "containers/Dashboard/Overview";
import Reports from "containers/Dashboard/Reports";
import ScaleOperatorOverview from "containers/Dashboard/ScaleOperatorOverview";
import { NLISFiles } from "containers/NLISFiles";
import { ConfirmManualTransfer } from "containers/NLISFiles/ConfirmManualTransferModal";
import RegisteredBiddersView from "containers/RegisteredBiddersView";
import EditSaleLotModal from "containers/SaleLot/Adapter";
import Sell from "containers/Sell";
import Take from "containers/Take";
import WeighbridgeSaleViewRoute from "containers/WeighbridgeSaleView";

import { getSaleEntryRoute, getSaleyardAuctionRoute } from "lib/navigation";

import {
  getCurrentSaleyard,
  getIsBusinessUser,
  getIsLivestockAgent,
  getIsSaleWatcher,
  selectCurrentDeployments,
  selectRoleCurrentDeployments,
} from "selectors";

const SaleTypeOverviewComponentMap = {
  [SaleTypes.BOBBYCALF]: ScaleOperatorOverview,
  [SaleTypes.CLEARING]: Overview,
  [SaleTypes.ON_FARM_AUCTION]: Overview,
  [SaleTypes.OVER_HOOKS]: HooksSale,
  [SaleTypes.PADDOCK]: PaddockSale,
  [SaleTypes.SALEYARD_AUCTION]: Overview,
  [SaleTypes.SIGHTING]: Overview, // This is not yet implemented
  [SaleTypes.EXTERNAL_AGENT_SALE]: ExternalAgentSaleOverview,
};

function getSaleTypeOverviewComponent(saleType) {
  return SaleTypeOverviewComponentMap[saleType] || Overview;
}

const SaleLotsTableComponentMap = {
  [SaleTypes.OVER_HOOKS]: PrivateSaleSaleLotsTable,
  [SaleTypes.PADDOCK]: PrivateSaleSaleLotsTable,
};

function getSaleLotsTableComponent(saleType) {
  return SaleLotsTableComponentMap[saleType] || SaleLotsTable;
}

export function SaleRoutes(props) {
  const { saleId, saleType, saleyardName, saleyardId } = props;
  const isBusinessUser = useSelector(getIsBusinessUser);
  const isSaleWatcher = useSelector(getIsSaleWatcher);
  const isLivestockAgent = useSelector(getIsLivestockAgent);

  const saleyard = useSelector(getCurrentSaleyard);
  const allowLivestockAgentNlisRoute =
    saleyard?.isAgentManagedNlisYard && isLivestockAgent;

  const salePath = getSaleyardAuctionRoute(":saleyard", ":saleId");

  let defaultRoute = null;
  if (isBusinessUser) {
    defaultRoute = "reports";
  } else if (isSaleWatcher) {
    defaultRoute = "watch";
  }
  const defaultRedirect = getSaleEntryRoute(
    ":saleyard",
    ":saleId",
    saleType,
    defaultRoute,
  );

  if (isBusinessUser) {
    return (
      <Switch>
        <Route path={`${salePath}/scans`} component={ScanTable} />
        <Route path={`${salePath}/reports`} component={Reports} />
        <Redirect exact path={salePath} to={defaultRedirect} />
      </Switch>
    );
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${salePath}/overview`}
          component={getSaleTypeOverviewComponent(saleType)}
        />
        <Route
          exact
          path={`${salePath}/businesses-in-sale`}
          component={BusinessesInSaleTable}
        />
        <Route
          exact
          path={`${salePath}/business-alternatives`}
          component={ResolveAlternatives}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureConsignments}
          path={`${salePath}/consignment-cards`}
          component={ConsignmentCardsView}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featurePens}
          path={`${salePath}/auction-pen-cards`}
          component={AuctionPenCardsView}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureBuyers}
          path={`${salePath}/buyer-cards`}
          component={BuyerCardsView}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureAttachments}
          path={`${salePath}/attachments`}
          component={Attachments}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureNominations}
          path={`${salePath}/nominations`}
          component={NominationTable}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureNominations}
          path={`${salePath}/nomination-cards`}
          component={NominationCards}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureConsignments}
          path={`${salePath}/consignments`}
          component={ConsignmentsTable}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectRoleCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureAuction}
          exact
          path={`${salePath}/firstround`}
          component={AuctionPenSale}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureScans}
          path={`${salePath}/eids`}
          component={ScanTable}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureEContracts}
          path={`${salePath}/econtracts`}
          component={EContractView}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureReports}
          path={`${salePath}/reports`}
          component={Reports}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureSaleLots}
          path={`${salePath}/salelots`}
          component={getSaleLotsTableComponent(saleType)}
        />
        <Route path={`${salePath}/take`} component={Take} />
        <Route path={`${salePath}/sell`} component={Sell} />
        <SecurityTrimmedRoute
          objectArraySelector={selectCurrentDeployments}
          permissionRequired={DeploymentPermissions.featureScans}
          path={`${salePath}/scans`}
          component={ScanTable}
        />
        <Route path={`${salePath}/buyers`} component={Buyers} />
        <Route path={`${salePath}/vendors`} component={Vendors} />
        <Route path={`${salePath}/catalogue`} component={Catalogue} />
        {isLivestockAgent && (
          <Route exact path={`${salePath}/import`} component={SG8Importer} />
        )}
        {isLivestockAgent && (
          <Route
            exact
            path={`${salePath}/ap-import`}
            component={AuctionsPlusImporter}
          />
        )}
        {isLivestockAgent && (
          <Route
            exact
            path={`${salePath}/ex-agent-import`}
            component={ExternalAgentXmlImporter}
          />
        )}
        {isLivestockAgent && (
          <Route
            exact
            path={`${salePath}/csv-presale-import`}
            component={PreSaleCsvImporter}
          />
        )}
        <SecurityTrimmedRoute
          objectSelector={getCurrentSaleyard}
          permissionRequired={SaleyardPermissions.featureWatcher}
          path={`${salePath}/watch`}
          component={SaleWatcher}
        />
        <SecurityTrimmedRoute
          objectSelector={getCurrentSaleyard}
          permissionRequired={SaleyardPermissions.featureReceivalLots}
          path={`${salePath}/arrival-scanning`}
          component={ReceivalLotsTable}
        />
        <SecurityTrimmedRoute
          objectSelector={getCurrentSaleyard}
          permissionRequired={SaleyardPermissions.featureReceivalLots}
          path={`${salePath}/arrival-scanning-cards`}
          component={ArrivalScanningView}
        />
        <SecurityTrimmedRoute
          objectSelector={getCurrentSaleyard}
          permissionRequired={SaleyardPermissions.featurePenScanLots}
          path={`${salePath}/pen-scanning`}
          component={PenScanLotsTable}
        />
        <SecurityTrimmedRoute
          objectSelector={getCurrentSaleyard}
          permissionRequired={SaleyardPermissions.featurePenScanLots}
          path={`${salePath}/pen-scanning-cards`}
          component={PenScanningView}
        />
        <SecurityTrimmedRoute
          objectSelector={getCurrentSaleyard}
          permissionRequired={SaleyardPermissions.featureWeighLots}
          path={`${salePath}/weigh-scanning`}
          component={WeighScanLotsTable}
        />
        <SecurityTrimmedRoute
          objectSelector={getCurrentSaleyard}
          permissionRequired={SaleyardPermissions.featureBidders}
          path={`${salePath}/bidder-cards`}
          component={RegisteredBiddersView}
        />
        <FeatureRoute
          roleDeploymentPermissions={[DeploymentPermissions.featureBulkWeigh]}
          saleyardPermissions={[SaleyardPermissions.featureBulkWeigh]}
          path={`${salePath}/weighbridge`}
          component={WeighbridgeSaleViewRoute}
        />
        {allowLivestockAgentNlisRoute ? (
          <Route path={`${salePath}/nlis`} component={NLISFiles} />
        ) : (
          <SecurityTrimmedRoute
            objectSelector={getCurrentSaleyard}
            permissionRequired={SaleyardPermissions.featureNlis}
            path={`${salePath}/nlis`}
            component={NLISFiles}
          />
        )}
        <SecurityTrimmedRoute
          objectArraySelector={selectRoleCurrentDeployments}
          permissionRequired={[
            DeploymentPermissions.featureBilling,
            DeploymentPermissions.featureManualCharges,
            DeploymentPermissions.featureBillingRun,
          ]}
          path={`${salePath}/billing/ledger-entry-detail`}
          component={LedgerEntryDetail}
        />
        <SecurityTrimmedRoute
          objectArraySelector={selectRoleCurrentDeployments}
          permissionRequired={[
            DeploymentPermissions.featureBilling,
            DeploymentPermissions.featureManualCharges,
            DeploymentPermissions.featureBillingRun,
          ]}
          path={`${salePath}/billing`}
          component={BillingWorkFlow}
        />
        <Route
          exact
          path={`${salePath}/lot-sheet/:saleLotId/`}
          component={LotSheet}
        />
        <Route
          exact
          path={`${salePath}/placard/:saleLotId?/:round?/:agentId?`}
          component={RenderPlacardV1}
        />
        <Route
          exact
          path={`${salePath}/placardv2/:saleLotId?/:round?/:agentId?`}
          component={RenderPlacardV2}
        />
        <Route
          exact
          path={`${salePath}/placardv5/:saleLotId?/:round?/:agentId?`}
          component={RenderPlacardV5}
        />
        <Route
          exact
          path={`${salePath}/placardv3/:saleLotId?/:round?/:agentId?/A3`}
          component={RenderA3PlacardV3}
        />
        <Route
          exact
          path={`${salePath}/placardv3/:saleLotId?/:round?/:agentId?/A4`}
          component={RenderA4PlacardV3}
        />
        <Route
          exact
          path={`${salePath}/placardv4/:saleLotId?/:round?/:agentId?`}
          component={RenderPlacardV4}
        />
        <Route
          exact
          path={`${salePath}/sheepplacard/:saleLotId?/:round?/:agencyId?`}
          component={SheepPlacard}
        />
        <Route
          exact
          path={`${salePath}/sheeppenstorecard/:saleLotId?/:round?/:agencyId?`}
          component={SheepPenStoreCard}
        />
        {/* Send /saleyard/Ballarat/sale/1234 to overview (or reports) */}
        <Redirect exact path={salePath} to={defaultRedirect} />
      </Switch>
      <HashRoute
        component={EditSaleLotModal}
        hash={ModalTypes.EditSaleLot}
        componentProps={{ saleyardId, saleId }}
      />
      <HashRoute
        component={DeliverSaleLotModalAdapter}
        hash={ModalTypes.DeliverSaleLot}
      />
      <HashRoute
        component={DeliveryPenModalAdapter}
        hash={ModalTypes.DeliveryPen}
      />

      <HashRoute
        component={EmailReportModalAdapter}
        hash={ModalTypes.EmailReportModal}
      />
      <HashRoute
        component={ScanToPenModalAdapter}
        hash={ModalTypes.ScanToPen}
      />
      <HashRoute
        component={SplitSaleLotModal}
        hash={ModalTypes.SplitSaleLot}
        componentProps={{ saleyardId }}
      />

      <HashRoute
        component={SundryModalApapter}
        hash={ModalTypes.ManualAdjustmentForm}
      />

      <HashRoute
        component={ManualAdjustmentTableModal}
        hash={ModalTypes.ManualAdjustmentTable}
      />

      <HashRoute component={MergeSaleLotModal} hash={ModalTypes.MergeSaleLot} />
      <HashRoute component={KillSheetModal} hash={ModalTypes.KillSheet} />
      <HashRoute
        component={SaleLotCarrierChargeModalAdapter}
        hash={ModalTypes.EditSaleLotCarrierCharge}
      />
      <HashRoute
        component={ConsignmentCarrierChargeModalAdapter}
        hash={ModalTypes.ConsignmentCarrierCharge}
      />
      <HashRoute
        component={SendVendorReportsModal}
        hash={ModalTypes.SendVendorReports}
      />

      <HashRoute
        component={UpdateBuyerModalAdapter}
        hash={ModalTypes.UpdateBuyer}
      />
      <HashRoute
        component={EditConsignmentModal}
        hash={ModalTypes.Consignment}
        componentProps={{ saleId }}
      />
      <HashRoute
        component={EditBidderModalAdapter}
        hash={ModalTypes.EditBidder}
        componentProps={{ saleId, saleyardName }}
      />
      <HashRoute component={Scan} hash={ModalTypes.Scan} />
      <HashRoute
        hash={ModalTypes.BusinessPayments}
        component={BusinessPaymentsModalAdapter}
      />
      <HashRoute
        component={EditPaymentModalAdapter}
        hash={ModalTypes.EditPayment}
      />
      <HashRoute
        component={DuplicateBusinessModal}
        hash={ModalTypes.DuplicateBusinessSuggestions}
      />
      <HashRoute
        component={InlineCreateSaleLotModalAdapter}
        hash={ModalTypes.InlineCreateSaleLot}
      />
      <HashRoute component={CommentsModalAdapter} hash={ModalTypes.Comments} />
      <HashRoute
        component={DeliveryPenOwnersModalAdapter}
        hash={ModalTypes.DeliveryPenOwners}
      />
      <HashRoute
        component={EditCheckpointModalAdapter}
        hash={ModalTypes.EditCheckpoint}
      />
      <HashRoute component={SaleLotModalAdapter} hash={ModalTypes.SaleLot} />
      <HashRoute
        component={VendorSplitConsignmentModalAdapter}
        hash={ModalTypes.VendorSplitConsignment}
      />
      <HashRoute
        component={VendorSplitSaleLotModalAdapter}
        hash={ModalTypes.VendorSplitSaleLot}
      />

      <HashRoute
        component={EditPenScanLotModalAdapter}
        hash={ModalTypes.EditPenScanLot}
      />
      <HashRoute
        component={EditScanningPenModalAdapter}
        hash={ModalTypes.EditScanningPen}
      />
      <HashRoute
        component={EditReceivalLotModalAdapter}
        hash={ModalTypes.EditReceivalLot}
      />
      <HashRoute
        component={DispatchingHashModalAdapter}
        componentProps={{
          modalComponent: AllocatePenScanLotsModal,
        }}
        hash={ModalTypes.AllocatePenScanLots}
      />
      <HashRoute
        component={DispatchingHashModalAdapter}
        hash={ModalTypes.ForceTransfer}
        componentProps={{ modalComponent: ConfirmManualTransfer }}
      />
      <HashRoute
        component={DispatchingHashModalAdapter}
        hash={ModalTypes.ImportWeighLots}
        componentProps={{ modalComponent: ImportWeighLotsModal }}
      />
      <HashRoute
        component={WeightSettingsModal}
        hash={ModalTypes.WeightSettings}
      />
      <HashRoute
        component={DispatchingHashModalAdapter}
        hash={ModalTypes.DefaultBuyerWayProperty}
        componentProps={{ modalComponent: UpdateDefaultPropertyModal }}
      />
    </>
  );
}

SaleRoutes.propTypes = {
  saleId: PropTypes.number.isRequired,
  saleType: PropTypes.string.isRequired,
  saleyardName: PropTypes.string.isRequired,
  saleyardId: PropTypes.number.isRequired,
};
