import React, { useCallback } from "react";

import { Grid } from "@material-ui/core";
import { getIn } from "formik";
import { useSelector } from "react-redux";

import {
  Input,
  SelectField,
  TextArea,
  withNamespace,
} from "components/Form/FormikControls";

import { groupedLineItemToolTipText } from "constants/billing";
import { RuleValueFormatOptionsByRawValueFormat } from "constants/ruleBooks";

import { RuleOutputFormatField } from "containers/Settings/RuleBooks/Rules/CustomRules/RuleOutputFormatField";

import { EMPTY_OBJECT } from "lib";

import {
  selectCustomisationsByRuleIdLookup,
  selectLedgerAccountOptionsForRuleBuilder,
} from "selectors";

import { useFieldValue } from "hooks";

export function CustomiseManagedRuleForm(props) {
  const { namespace: ns, ruleId } = props;

  const LedgerAccountOptions = useSelector(
    selectLedgerAccountOptionsForRuleBuilder,
  );

  const value = useFieldValue(ns);

  const unitAmountRawFormat = getIn(value, "unit_amount_raw_format");
  const unitAmountOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[unitAmountRawFormat] || [];

  const quantityRawFormat = getIn(value, "quantity_raw_format");
  const quantityOutputFormatOptions =
    RuleValueFormatOptionsByRawValueFormat[quantityRawFormat] || [];

  const customisedFields =
    useSelector(selectCustomisationsByRuleIdLookup)[ruleId] || EMPTY_OBJECT;

  const getAltToolTip = useCallback(
    field => {
      if (value[field] && customisedFields[field]) {
        return `Overrides default value "${customisedFields[field]}"`;
      }
    },
    [customisedFields, value],
  );

  return (
    <>
      <Grid item xs={12}>
        <Input
          name={withNamespace(ns, "name")}
          label="Name"
          placeholder={customisedFields.name}
          altTooltip={getAltToolTip("name")}
        />
      </Grid>

      <Grid item xs={12}>
        <TextArea
          name={withNamespace(ns, "comment")}
          label="Comment"
          placeholder={customisedFields.comment}
          altTooltip={getAltToolTip("comment")}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          name={withNamespace(ns, "title_template")}
          label="Title Template"
          altTooltip={getAltToolTip("title_template")}
          placeholder={customisedFields.title_template}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          name={withNamespace(ns, "invoice_line_item_template")}
          label="Document Grouped Line Description"
          tooltip={groupedLineItemToolTipText}
          altTooltip={getAltToolTip("invoice_line_item_template")}
          placeholder={customisedFields.invoice_line_item_template}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectField
          label="GL Code"
          name={withNamespace(ns, "gl_code")}
          required
          options={LedgerAccountOptions}
          menuPortalTarget={document.body}
          altTooltip={getAltToolTip("gl_code")}
        />
      </Grid>

      <Grid item xs={6}>
        <RuleOutputFormatField
          label="Units Format"
          name="quantity_output_format"
          options={quantityOutputFormatOptions}
          altTooltip={getAltToolTip("quantity_output_format")}
        />
      </Grid>
      <Grid item xs={6}>
        <RuleOutputFormatField
          label="Unit Price Format"
          name="unit_amount_output_format"
          options={unitAmountOutputFormatOptions}
          altTooltip={getAltToolTip("unit_amount_output_format")}
        />
      </Grid>
    </>
  );
}
