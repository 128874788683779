import React, { useState } from "react";

import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { RuleAction } from "actions";

import { MultiButton } from "components/Button";
import { Button, SecondaryButton } from "components/Form";
import { useSubmitHandler } from "components/Form/FormikControls";
import { DialogActions, DialogContent } from "components/MaterialDialog";

import { ModalTypes } from "constants/navigation";
import { RuleBookPermissions } from "constants/permissions";

import { CustomiseManagedRuleForm } from "containers/Settings/RuleBooks/Rules/ManagedRules";

import { openModalLink } from "lib/navigation";

import { getIsStaff, getRuleBookById, getRuleById } from "selectors";

import { useHasPermission } from "hooks";

function Footer(props) {
  const { onClose, ruleBookId } = props;
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  useSubmitHandler(true, setIsSubmitEnabled);

  const hasUpdatePermission = useHasPermission(
    getRuleBookById(ruleBookId),
    RuleBookPermissions.update,
  );

  return (
    <>
      <SecondaryButton type="button" onClick={onClose}>
        Close
      </SecondaryButton>
      <Button
        type="submit"
        disabled={!(isSubmitEnabled && hasUpdatePermission)}
        title={
          !hasUpdatePermission
            ? "You do not have permission to Customise Rules"
            : "Customise Rule"
        }
      >
        Save
      </Button>
    </>
  );
}

export function CustomiseManagedRuleModalForm(props) {
  const { ruleId, onClose } = props;

  const dispatch = useDispatch();

  const managedRule = useSelector(getRuleById(ruleId));

  const ruleBookId = managedRule.rule_book_id;

  const initialValues = {
    comment: managedRule.comment,
    gl_code: managedRule.gl_code,
    name: managedRule.name,
    title_template: managedRule.title_template,
    invoice_line_item_template: managedRule.invoice_line_item_template,
    quantity_output_format: managedRule.quantity_output_format,
    quantity_raw_format: managedRule.quantity_raw_format,
    unit_amount_output_format: managedRule.unit_amount_output_format,
    unit_amount_raw_format: managedRule.unit_amount_raw_format,
  };

  function onSubmit(values) {
    dispatch(
      RuleAction.update(
        { ...values, id: ruleId },
        {
          changeReason: "Customised from Rule Modal",
        },
      ),
    );
    onClose();
  }

  function editMasterRule() {
    openModalLink(
      ModalTypes.EditMasterRule,
      { masterRuleId: managedRule.master_rule_id },
      window.location.hash,
    );
  }

  function editRuleBook() {
    openModalLink(ModalTypes.RuleBook, {
      id: managedRule.rule_book_id,
    });
  }

  const isStaff = useSelector(getIsStaff);

  const staffActions = [
    {
      title: "Edit Master Rule",
      onClick: editMasterRule,
      dataTour: "editMasterRule",
    },
    {
      title: "Edit Rule Book",
      onClick: editRuleBook,
      dataTour: "editMasterRule",
    },
  ];

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              This rule is managed by AgriNous.
              {isStaff && <MultiButton buttons={staffActions} />}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <CustomiseManagedRuleForm
              ruleBookId={ruleBookId}
              namespace=""
              ruleId={ruleId}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Footer onClose={onClose} ruleBookId={ruleBookId} />
        </DialogActions>
      </Form>
    </Formik>
  );
}
