import React, { useEffect, useMemo, useRef, useState } from "react";

import { faPencil, faRotateReverse } from "@fortawesome/pro-duotone-svg-icons";
import {
  faFileInvoiceDollar,
  faFiles,
} from "@fortawesome/pro-regular-svg-icons";
import { faFunnelDollar } from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@material-ui/core";
import { subDays } from "date-fns";
import { kebabCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import * as Yup from "yup";

import {
  BillingDocumentAction,
  bulkUpdateBillingDocuments,
  PaymentAction,
  SaleAction,
  setSetting,
} from "actions";

import { IntegrationCredentialAction } from "actions/integrationCredentials";

import AgGridTable, {
  globalDefaultColDef,
} from "components/AgGrid/AgGridContainer";
import { LoadAll } from "components/AgGrid/LoadAll";
import {
  BulkUpdateOptionalFieldsModal,
  getCheckboxFieldName,
} from "components/BulkUpdateOptionalFieldsModal";
import { DateInput, SelectField } from "components/Form/FormikControls";
import { Column } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { useGetDownloadOutstandingSettlementReport } from "components/MoneyTable/outstandingSettlementReport";
import { GenerateMode } from "components/MultiSaleReports";
import { TitleText } from "components/Text";
import { TimeSince } from "components/TimeSince";

import { AgGridTables } from "constants/aggrid";
import { BillingDocumentStatusOptions } from "constants/billingDocuments";
import { IntegrationTypes } from "constants/integrations";
import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";
import { DeploymentPermissions } from "constants/permissions";
import { Settings } from "constants/settings";

import { firstIfAllEqual } from "lib";

import { EmailDetailGridOptions } from "lib/agGrid/columnDefinitions/email";
import { openModalLink } from "lib/navigation";
import toast from "lib/toast";

import {
  getActiveLivestockAgentDeployment,
  getActiveRole,
  getBillingDocuments,
  getIntegrationCredentialById,
  getIntegrationCredentialIdsByType,
  getIsFetchingBillingDocuments,
  getProperties,
  getSetting,
  selectBillingDocumentsAggridDataById,
} from "selectors";

import {
  useBoolean,
  useHasDeploymentPermission,
  useIsMobile,
  useMountEffect,
} from "hooks";

import { MoneyColumnDefinitions } from "./columnDefinitions";

const getRowId = params => params.data.billingDocument.id;
const rowSelectionId = "billingDocument.id";

const detailCellRendererParams = {
  detailGridOptions: EmailDetailGridOptions,
  getDetailRowData: params => {
    params.successCallback(params.data.emails);
  },
};

const LastSyncedTextWrapper = styled.p`
  margin-right: 20px;
`;

const billingDocumentBulkUpdateFields = {
  status: {
    Component: SelectField,
    componentProps: {
      options: BillingDocumentStatusOptions,
      menuPortalTarget: document.body,
      label: "Status",
      required: true,
    },
  },
  dueDate: {
    Component: DateInput,
    componentProps: {
      label: "Due Date",
      required: true,
    },
  },
};

const bulkValidationSchema = Yup.object().shape({
  [getCheckboxFieldName("status")]: Yup.boolean(),
  status: Yup.string().when(getCheckboxFieldName("status"), {
    is: true,
    then: Yup.string().required("Status is required"),
  }),
  [getCheckboxFieldName("dueDate")]: Yup.boolean(),
  dueDate: Yup.date().when(getCheckboxFieldName("dueDate"), {
    is: true,
    then: Yup.date().required("Due Date is required"),
  }),
});

export const MoneyTable = ({ allDocumentsLoaded, setAllDocumentsLoaded }) => {
  const [selectedBillingDocumentIds, setSelectedBillingDocumentIds] = useState(
    [],
  );
  const propertyByIdLookup = useSelector(getProperties);
  const userRoleSlug = useSelector(state => getActiveRole(state).slug);
  const isDocumentsFetching = useSelector(getIsFetchingBillingDocuments);

  const context = {
    propertyByIdLookup,
    userRoleSlug,
  };
  const rowData = Object.values(
    useSelector(selectBillingDocumentsAggridDataById),
  );
  const dispatch = useDispatch();

  const xeroCredentialIds = useSelector(
    getIntegrationCredentialIdsByType(IntegrationTypes.Xero),
  );

  const xeroCredentialId = xeroCredentialIds?.[0];

  const integrationCredential = useSelector(
    getIntegrationCredentialById(xeroCredentialId),
  );

  const lastSync = integrationCredential ? (
    <LastSyncedTextWrapper>
      <i>
        Integration synced:{" "}
        {integrationCredential.integrationDocumentsLastSyncedAt
          ? TimeSince({
              utcDateTimeStr:
                integrationCredential.integrationDocumentsLastSyncedAt,
            })
          : "never"}
      </i>
    </LastSyncedTextWrapper>
  ) : null;

  const syncXeroDocuments = () =>
    dispatch(IntegrationCredentialAction.syncDocuments(xeroCredentialId));

  const syncingDocumentToastText = "Fetching All Documents";
  const loadAllDocuments = () => {
    dispatch(BillingDocumentAction.request());
    toast.syncing(syncingDocumentToastText, {
      autoClose: false,
    });
    setAllDocumentsLoaded(true);
  };

  useEffect(() => {
    if (!isDocumentsFetching) {
      toast.dismiss(kebabCase(syncingDocumentToastText));
    }
  }, [isDocumentsFetching]);

  const loadAllLabel = (
    <LoadAll
      allLoaded={allDocumentsLoaded}
      onClick={loadAllDocuments}
      text="Only unpaid documents and those modified within 90 days are shown."
    />
  );

  const billingDocumentByIdLookup = useSelector(getBillingDocuments);

  const [
    isEditBillingDocumentsOpen,
    openEditBillingDocuments,
    closeEditBillingDocuments,
  ] = useBoolean(false);

  const selectedBillingDocuments = useMemo(
    () =>
      selectedBillingDocumentIds.map(
        billingDocumentId => billingDocumentByIdLookup[billingDocumentId],
      ),

    [billingDocumentByIdLookup, selectedBillingDocumentIds],
  );

  const bulkInitialValues = useMemo(
    () => ({
      status: firstIfAllEqual(
        selectedBillingDocuments.map(billingDocument => billingDocument.status),
        undefined,
      ),
      dueDate: firstIfAllEqual(
        selectedBillingDocuments.map(
          billingDocument => billingDocument.dueDate,
        ),
        undefined,
      ),
    }),
    [selectedBillingDocuments],
  );
  const selectedCount = selectedBillingDocumentIds.length;

  const [onGridReadyExtra, handleDownloadOutstandingSettlementReport] =
    useGetDownloadOutstandingSettlementReport();

  const agGridRef = useRef();

  const generateInterestCharges = () => {
    openModalLink(ModalTypes.Interest);
  };

  const onClickGenerateFinancialStatements = () => {
    openModalLink(ModalTypes.MultiSaleReports, {
      allowedReports: [
        "FinancialStatementReport",
        "FinancialStatementZipReport",
      ],
      generateMode: GenerateMode.MANY,
    });
  };

  function onGridReady(agGrid) {
    agGridRef.current = agGrid;
    onGridReadyExtra(agGrid);
  }

  const hasInterestFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureInterest,
  );

  const activeDeployment = useSelector(getActiveLivestockAgentDeployment);
  const interestEnabled =
    hasInterestFeature && activeDeployment.deploymentSettings?.enableInterest;
  const additionalActions = [
    {
      title: "Download Outstanding Settlement Report",
      icon: faFunnelDollar,
      onClick: handleDownloadOutstandingSettlementReport,
      dataTour: "outstandingSettlementReport",
    },
    integrationCredential && {
      title: "Sync Financial Integrations",
      icon: faRotateReverse,
      onClick: syncXeroDocuments,
      dataTour: "syncFinancialIntegrations",
      ref: "syncFinancialIntegrations",
    },
    interestEnabled && {
      title: "Generate Interest Charges",
      icon: faFileInvoiceDollar,
      onClick: generateInterestCharges,
      dataTour: "generateInterestCharges",
    },
    {
      title: `Bulk Edit (${selectedCount})`,
      icon: faPencil,
      isDisabled: selectedCount === 0,
      onClick: openEditBillingDocuments,
      dataTour: "bulkUpdate",
    },
    {
      title: "Generate Financial Reports",
      icon: faFiles,
      onClick: onClickGenerateFinancialStatements,
      dataTour: "generateFinancialStatements",
    },
  ].filter(Boolean);

  const isMobile = useIsMobile();

  const onRowSelectionChange = rows => {
    // This logic is already performed in the AgGridTable component :/
    setSelectedBillingDocumentIds(rows.map(r => r.billingDocument.id));
  };

  return (
    <Column fullHeight>
      <Box p={2}>
        <TitleText>Money</TitleText>
      </Box>
      <AgGridTable
        defaultColDef={{
          ...globalDefaultColDef,
          enableCellChangeFlash: false,
        }}
        rowSelectionId={rowSelectionId}
        columnDefs={MoneyColumnDefinitions(interestEnabled)}
        context={context}
        extraHeaderComponents={[loadAllLabel, lastSync]}
        getRowId={getRowId}
        rowData={rowData}
        additionalActions={additionalActions}
        onGridReady={onGridReady}
        tableName={AgGridTables.MONEY}
        detailCellRendererParams={detailCellRendererParams}
        onRowSelectionChanged={onRowSelectionChange}
        rowSelection="multiple"
        masterDetail
        headerJustifyContent={isMobile ? "space-around" : "space-between"} // This table might not be used a lot in mobile, but lets still make it look nice
        multiSelectActionOnly
        suppressRowClickSelection
        defaultCsvExportParams={{
          // only export selected rows to the export csv - if no rows are selected export all
          onlySelected: !!selectedBillingDocumentIds.length,
        }}
        rowsSelectable
      />
      {isEditBillingDocumentsOpen && (
        <BulkUpdateOptionalFieldsModal
          onClose={closeEditBillingDocuments}
          modelIds={selectedBillingDocumentIds}
          modelName="Billing Document"
          bulkUpdateActionCreator={bulkUpdateBillingDocuments}
          fieldsAndComponentsMap={billingDocumentBulkUpdateFields}
          initialValues={bulkInitialValues}
          validationSchema={bulkValidationSchema}
        />
      )}
    </Column>
  );
};

export const MoneyTableWrapper = () => {
  const forceNotLoading = useSelector(state => {
    const billingRuns = getBillingDocuments(state);
    return Object.keys(billingRuns).length > 0;
  });

  const [allDocumentsLoaded, setAllDocumentsLoaded] = useState(false);

  const dispatch = useDispatch();

  const allSalesLoaded = useSelector(getSetting(Settings.allSalesLoaded));
  // We only want to fetch these once on mount, and outside of the <WaitFor> block to avoid rerenders forcing re-gets.
  useMountEffect(() => {
    dispatch(
      BillingDocumentAction.request({
        extraQueryParams: { recent_or_overdue: true },
      }),
    );
    // TODO AG-4734 bring this filter to be inline with the new filters coming for documents
    dispatch(PaymentAction.request({ createdGte: subDays(new Date(), 90) }));
    if (!allSalesLoaded) {
      dispatch(setSetting(Settings.allSalesLoaded, true));
      dispatch(SaleAction.request({ fetchAll: true }));
    }
  });

  return (
    <WaitForSync
      requiredData={[
        ApiModel.BILLING_DOCUMENTS,
        ApiModel.INTEGRATION_CREDENTIALS,
        ApiModel.SALES,
        ApiModel.PAYMENTS,
      ]}
      forceNotLoading={forceNotLoading}
    >
      <MoneyTable
        allDocumentsLoaded={allDocumentsLoaded}
        setAllDocumentsLoaded={setAllDocumentsLoaded}
      />
    </WaitForSync>
  );
};
