import React from "react";

import {
  faEye,
  faFileCsv,
  faPencil,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { isEmpty, orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import { BillingRunAction } from "actions";

import { openSaleExport } from "components/BillingWorkFlow/SaleExport";
import { Warnings } from "components/BillingWorkFlow/Warnings";
import { MultiButton } from "components/Button";
import { Warning } from "components/ErrorMessage";
import { Button, FormSectionGrid } from "components/Form";
import { Column, Row } from "components/Layout";
import MessageBox from "components/MessageBox";
import { Paper } from "components/Paper";
import { DescriptiveOption, ReactSelect } from "components/SearchableSelector";
import { Text } from "components/Text";

import { ModalTypes } from "constants/navigation";

import {
  getLivestockSaleId,
  openLedgerEntryDetail,
  openModalLink,
  openSaleModal,
} from "lib/navigation";
import {
  formatDateTimeUTC,
  formatUTCToLocalDateTimeString,
} from "lib/timeFormats";

import {
  getActiveBillingRunId,
  getActiveRole,
  getBillingRuns,
  getCurrentDeploymentSalesList,
  getIsStaff,
} from "selectors";

import { getCurrentDefaultTradingTerms } from "selectors/tradingTerms";

const Table = styled.table`
  border-collapse: collapse;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;

  tr td {
    padding: 8px;
  }
`;

export const SpacedFormSectionGrid = styled(FormSectionGrid)`
  width: 80%;
  align-self: center;
  padding-top: 20px;
`;

const BillingRunSummary = () => {
  const activeBillingRunId = useSelector(getActiveBillingRunId);
  const allBillingRuns = useSelector(getBillingRuns);
  const location = useLocation();
  const billingRun = allBillingRuns[activeBillingRunId] || {};

  const openBillingRunForm = billingRunId => {
    openModalLink(
      ModalTypes.EditBillingRun,
      {
        billingRunId,
      },
      location.pathname + location.search,
    );
  };

  // Get active terms from the sale.
  const deploymentSale = useSelector(getCurrentDeploymentSalesList)?.[0];
  const userRoleSlug = useSelector(getActiveRole).slug;

  const { buyer_terms: buyerTerms, vendor_terms: vendorTerms } = deploymentSale;

  const isStaff = useSelector(getIsStaff);

  const dispatch = useDispatch();

  // default terms for the sale
  const { buyerTerms: defaultBuyerTerms, vendorTerms: defaultVendorTerms } =
    useSelector(getCurrentDefaultTradingTerms) || {};

  const defaultTermsOverridden =
    (defaultBuyerTerms && buyerTerms !== defaultBuyerTerms) ||
    (defaultVendorTerms && vendorTerms !== defaultVendorTerms);

  const openEditRuleBook = () =>
    openModalLink(ModalTypes.RuleBook, {
      id: billingRun.ruleBookId,
    });

  const selectOptions = orderBy(
    Object.values(allBillingRuns),
    "created",
    "desc",
  ).map(billingRun => {
    return {
      label: billingRun.name,
      value: billingRun.id,
      description: `Created: ${billingRun.created ? formatDateTimeUTC(billingRun.created) : ""} Last Run: ${billingRun.ledgerEntriesGeneratedDateTime ? formatDateTimeUTC(billingRun.ledgerEntriesGeneratedDateTime) : ""}`,
    };
  });

  const buttons = [
    {
      dataTour: "editBillingRun",
      title: "Edit",
      onClick: () => openBillingRunForm(billingRun.id),
      default: true,
    },
    {
      dataTour: "createBillingRun",
      title: "Create new Billing Run",
      onClick: () => openBillingRunForm(null),
      icon: faPlus,
    },
    {
      dataTour: "ledgerEntryDetail",
      title: "Ledger Entry Detail",
      onClick: openLedgerEntryDetail,
      icon: faEye,
    },
    {
      dataTour: "editRuleBook",
      title: "Edit Rule Book",
      onClick: openEditRuleBook,
      icon: faPencil,
    },
    {
      dataTour: "editTradingTerms",
      title: "Edit Trading Terms",
      onClick: () => openSaleModal(getLivestockSaleId()),
      icon: faPencil,
    },
    isStaff && {
      dataTour: "saleExport",
      title: "Sale Export",
      onClick: () => openSaleExport(userRoleSlug),
      icon: faFileCsv,
    },
  ].filter(Boolean);

  const selectBillingRunId = billingRunId => {
    if (!billingRunId) {
      dispatch(BillingRunAction.unsubscribe());
    } else {
      dispatch(BillingRunAction.subscribe(billingRunId));
    }
  };

  let selectedOption = selectOptions.find(
    option => option.value === activeBillingRunId,
  );

  if (!activeBillingRunId || isEmpty(billingRun)) {
    // If we have no billing run, we should select the first.
    // dispatch(BillingRunAction.unsubscribe());
    selectedOption = selectOptions[0];
    if (selectedOption) {
      selectBillingRunId(selectedOption.value);
    }
  }
  if (isEmpty(allBillingRuns)) {
    return (
      <Paper>
        <MessageBox>
          <Column>
            No Billing Runs have been created for this sale yet.
            <Button onClick={() => openBillingRunForm(null)}>
              Create one now
            </Button>
          </Column>
        </MessageBox>
      </Paper>
    );
  }

  return (
    <Paper>
      <Text>Billing Run</Text>
      <Row justifyBetween justifyCenter alignCenter padding={2} gridGap={2}>
        <Column fullWidth>
          <ReactSelect
            options={selectOptions}
            name="billingRunOptions"
            value={selectedOption}
            isClearable={false}
            onChange={({ value }) => selectBillingRunId(value)}
            menuPortalTarget={document.body}
            components={{ Option: DescriptiveOption }}
            disabled={selectOptions.length === 0}
          />
        </Column>
        <MultiButton buttons={buttons} />
      </Row>
      <Table>
        <tbody>
          <tr>
            <td>Status:</td>
            <td>{billingRun.status}</td>
          </tr>
          <tr>
            <td>Last Run:</td>
            <td>
              {formatUTCToLocalDateTimeString(
                billingRun.ledgerEntriesGeneratedDateTime,
              )}
            </td>
          </tr>
          <tr>
            <td>Exported At:</td>
            <td>
              {billingRun.exportedAtDateTime
                ? formatUTCToLocalDateTimeString(billingRun.exportedAtDateTime)
                : "-"}
            </td>
          </tr>
          <tr>
            <td>Comments:</td>
            <td>{billingRun.comments}</td>
          </tr>
          <tr>
            <td>
              <b>Trading Terms</b>
            </td>
          </tr>
          <tr>
            <td>Vendor Terms:</td>
            <td>{vendorTerms}</td>
          </tr>
          <tr>
            <td>Buyer Terms:</td>
            <td>{buyerTerms} </td>
          </tr>
          {defaultTermsOverridden && (
            <tr>
              <td colSpan={2}>
                <Warning>
                  The default terms for this sale are different than the terms
                  used. (Vendor: {defaultVendorTerms}, Buyer:{" "}
                  {defaultBuyerTerms})
                </Warning>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Warnings />
    </Paper>
  );
};

export const Overview = React.memo(() => {
  return (
    <SpacedFormSectionGrid>
      <Grid item xs={12}>
        <BillingRunSummary />
      </Grid>
    </SpacedFormSectionGrid>
  );
});
