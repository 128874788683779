import React, { useEffect, useState } from "react";

import { getIn, useFormikContext } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import BusinessRelationshipsTable from "components/BusinessForm/BusinessRelationshipsTable";
import FinanceAndAccounting from "components/BusinessForm/FinanceAndAccounting";
import { GeneralCollapse } from "components/BusinessForm/GeneralCollapse";
import VendorCommissions from "components/BusinessForm/VendorCommissions/VendorCommissions";
import { withNamespace } from "components/Form/FormikControls";

import { BusinessModalSection } from "constants/navigation";
import { DeploymentPermissions } from "constants/permissions";

import { ForLivestockAgent } from "containers/ForUserType";

import { useHasDeploymentPermission } from "hooks/useHasPermission";

import BusinessAddressTable from "./BusinessAddressTable";
import {
  BuyerWaysHeader,
  BuyerWaysTable,
  BuyerWayValidationSchema,
} from "./BuyerWays/BuyerWaysTable";
import DeploymentBusinessTable from "./DeploymentBusinessTable";
import DeploymentBusinessEmailRecipients from "./EmailRecipients/DeploymentEmailRecipients";
import IntegrationsTable from "./Integrations/IntegrationsTable";
import { PropertyValidationSchema } from "./Properties/DeploymentPropertyForm";
import {
  PropertiesHeader,
  PropertiesTable,
} from "./Properties/PropertiesTable";
import TransactionsTable from "./Transactions/TransactionsTable";

const BuyerWayTableValidationSchema = Yup.object().shape({
  editingValue: BuyerWayValidationSchema.nullable(),
});

const PropertiesTableValidationSchema = Yup.object().shape({
  editingValue: PropertyValidationSchema.nullable(),
});

export const DeploymentBusinessForm = props => {
  const {
    defaultTab,
    setIsSubFormActive,
    branches,
    business,
    allowBusinessRelations,
    agencyId,
    namespace: ns,
    defaultAddressSearch,
  } = props;
  const [isEditingProperty, setIsEditingProperty] = useState(false);
  const [isEditingBuyerWay, setIsEditingBuyerWay] = useState(false);
  const [isEditingEmailRecipient, setIsEditingEmailRecipient] = useState(false);
  const [editingRelationshipId, setEditingRelationshipId] = useState(false);
  const [section, setSection] = useState(
    defaultTab || BusinessModalSection.GENERAL,
  );

  const isEditingRelationship = Boolean(editingRelationshipId);

  const formikProps = useFormikContext();

  const {
    abn,
    id: businessId,
    isHobbyFarmer,
    abrLastChecked,
  } = formikProps.values;

  useEffect(() => {
    setIsSubFormActive(
      Boolean(
        isEditingProperty ||
          isEditingBuyerWay ||
          isEditingEmailRecipient ||
          isEditingRelationship,
      ),
    );
  }, [
    isEditingProperty,
    isEditingBuyerWay,
    isEditingEmailRecipient,
    isEditingRelationship,
    setIsSubFormActive,
  ]);

  const getItemIndex = (field, id) => {
    const { values } = formikProps;

    if (!id) {
      return -1;
    }
    return getIn(values, field).findIndex(v => v.id === id);
  };

  const cancelEditEmailRecipient = () => setIsEditingEmailRecipient(false);

  const relationshipsField = withNamespace(ns, "relationships");

  const deleteValueAtIndex = (field, id) => {
    const idx = getItemIndex(field, id);
    if (idx < 0) {
      return null;
    }
    const {
      values: existingValues,
      setFieldValue,
      setFieldTouched,
    } = formikProps;

    setFieldValue(field, [
      ...getIn(existingValues, field).slice(0, idx),
      ...getIn(existingValues, field).slice(idx + 1),
    ]);
    setFieldTouched(field);
  };

  const deleteRelationship = relationshipId => {
    deleteValueAtIndex(relationshipsField, relationshipId);
  };

  const cancelEditRelationship = () => {
    if (editingRelationshipId === true) {
      deleteRelationship(true);
    }
    setEditingRelationshipId(null);
  };

  const saveRelationship = values => {
    const {
      values: existingValues,
      setFieldValue,
      setFieldTouched,
    } = formikProps;

    const existingRelationships = getIn(existingValues, relationshipsField);
    const existingIndex = getItemIndex(
      relationshipsField,
      editingRelationshipId,
    );
    // If the id is "true", give it a real id.
    const actualRelationId =
      editingRelationshipId === true ? -Date.now() : editingRelationshipId;

    const relationship = {
      ...existingRelationships[existingIndex],
      ...values,
      id: actualRelationId,
    };
    const newRelationships = [
      ...existingRelationships.slice(0, existingIndex),
      relationship,
      ...existingRelationships.slice(existingIndex + 1),
    ];
    setFieldValue(relationshipsField, newRelationships);
    setFieldTouched(relationshipsField);
    setEditingRelationshipId(null);
  };

  const editProperty = () => setIsEditingProperty(true);
  const editEmailRecipient = () => setIsEditingEmailRecipient(true);
  const editRelationship = editingRelationshipId =>
    setEditingRelationshipId(editingRelationshipId);

  const addRelationship = () => {
    const { setFieldValue, setFieldTouched, values } = formikProps;
    const existingRelationships = getIn(values, relationshipsField);

    setFieldValue(relationshipsField, [
      ...(existingRelationships || []),
      {
        id: true,
        relationType: null,
        relatedToId: null,
        percentage: 0,
      },
    ]);
    setFieldTouched(relationshipsField);
    setEditingRelationshipId(true);
  };

  const onSetPrimaryContactEmail = emailAddress => {
    const { setFieldValue, setFieldTouched } = formikProps;
    const field = "primaryContactEmail";
    setFieldValue(field, emailAddress);
    setFieldTouched(field);
  };

  const toggleSection = newSection => {
    if (newSection === section) {
      setSection(null);
    } else {
      setSection(newSection);
    }
  };

  const toggleAddress = () => toggleSection(BusinessModalSection.ADDRESS);

  const toggleGeneral = () => toggleSection(BusinessModalSection.GENERAL);

  const toggleProperties = () => toggleSection(BusinessModalSection.PROPERTIES);

  const toggleIntegrations = () =>
    toggleSection(BusinessModalSection.INTEGRATIONS);

  const toggleBuyerWays = () => toggleSection(BusinessModalSection.BUYER_WAYS);

  const toggleEmailRecipients = () =>
    toggleSection(BusinessModalSection.EMAIL_RECIPIENTS);

  const toggleTransactions = () =>
    toggleSection(BusinessModalSection.TRANSACTIONS);

  const toggleBusinessRelationships = () =>
    toggleSection(BusinessModalSection.BUSINESS_RELATIONSHIPS);

  const toggleFinanceAndAccounting = () =>
    toggleSection(BusinessModalSection.FINANCE_AND_ACCOUNTING);

  const toggleVendorCommissions = () =>
    toggleSection(BusinessModalSection.VENDOR_COMMISSION_BANDS);

  const buyerWaysList = getIn(
    formikProps.values,
    withNamespace(ns, "buyerWays"),
  );

  const propertiesList = getIn(
    formikProps.values,
    withNamespace(ns, "properties"),
  );

  const primaryContactEmail = getIn(
    formikProps.values,
    withNamespace(ns, "primaryContactEmail"),
  );

  const canLinkExternalIntegration = useHasDeploymentPermission(
    DeploymentPermissions.canLinkExternalIntegration,
  );

  const hasVendorCommissionsFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureVendorCommissions,
  );

  const hasBillingFeature = useHasDeploymentPermission(
    DeploymentPermissions.featureBilling,
  );

  const relationshipsList = getIn(formikProps.values, relationshipsField);

  return (
    <>
      <GeneralCollapse
        isOpen={section === BusinessModalSection.GENERAL}
        onToggle={toggleGeneral}
        branches={branches}
        namespace={ns}
        businessId={business?.id}
      />
      <BusinessAddressTable
        isOpen={section === BusinessModalSection.ADDRESS}
        onToggle={toggleAddress}
        namespace={withNamespace(ns, "address")}
        defaultAddressSearch={defaultAddressSearch}
        businessId={business?.id}
      />
      <FinanceAndAccounting
        isOpen={section === BusinessModalSection.FINANCE_AND_ACCOUNTING}
        onToggle={toggleFinanceAndAccounting}
        namespace={ns}
        abn={abn}
        businessId={businessId}
        isHobbyFarmer={isHobbyFarmer}
        abrLastChecked={abrLastChecked}
      />
      {hasVendorCommissionsFeature && (
        <ForLivestockAgent>
          <VendorCommissions
            isOpen={section === BusinessModalSection.VENDOR_COMMISSION_BANDS}
            onToggle={toggleVendorCommissions}
            namespace={ns}
            businessId={businessId}
          />
        </ForLivestockAgent>
      )}
      <DeploymentBusinessTable
        initialValuesList={propertiesList}
        isOpen={section === BusinessModalSection.PROPERTIES}
        namespace={withNamespace(ns, "properties")}
        onStartEditing={editProperty}
        onEndEditing={() => setIsEditingProperty(false)}
        onToggle={toggleProperties}
        headerComponent={PropertiesHeader}
        tableComponent={PropertiesTable}
        validationSchema={PropertiesTableValidationSchema}
        dataTour="propertyCollapse"
      />
      {canLinkExternalIntegration && (
        <IntegrationsTable
          isOpen={section === BusinessModalSection.INTEGRATIONS}
          onToggle={toggleIntegrations}
          businessId={business?.id}
        />
      )}
      <DeploymentBusinessTable
        initialValuesList={buyerWaysList}
        isOpen={section === BusinessModalSection.BUYER_WAYS}
        namespace={withNamespace(ns, "buyerWays")}
        onStartEditing={() => setIsEditingBuyerWay(true)}
        onEndEditing={() => setIsEditingBuyerWay(false)}
        onToggle={toggleBuyerWays}
        headerComponent={BuyerWaysHeader}
        tableComponent={BuyerWaysTable}
        validationSchema={BuyerWayTableValidationSchema}
        dataTour="buyerWayCollapse"
      />
      <DeploymentBusinessEmailRecipients
        isOpen={section === BusinessModalSection.EMAIL_RECIPIENTS}
        masterBusinessId={business?.id}
        primaryContactEmail={primaryContactEmail}
        onStartEditing={editEmailRecipient}
        onEndEditing={cancelEditEmailRecipient}
        onSetPrimaryContactEmail={onSetPrimaryContactEmail}
        onToggle={toggleEmailRecipients}
        namespace={ns}
      />
      {hasBillingFeature && (
        <TransactionsTable
          isOpen={section === BusinessModalSection.TRANSACTIONS}
          namespace={ns}
          masterBusinessId={business?.id}
          onToggle={toggleTransactions}
          tableComponent={TransactionsTable}
        />
      )}
      {allowBusinessRelations && (
        <BusinessRelationshipsTable
          isOpen={section === BusinessModalSection.BUSINESS_RELATIONSHIPS}
          onToggle={toggleBusinessRelationships}
          relationships={relationshipsList}
          addRelationship={addRelationship}
          editingId={editingRelationshipId}
          saveRelationship={saveRelationship}
          deleteRelationship={deleteRelationship}
          editRelationship={editRelationship}
          cancelEditRelationship={cancelEditRelationship}
          agencyId={agencyId}
        />
      )}
    </>
  );
};

DeploymentBusinessForm.propTypes = {
  allowBusinessRelations: PropTypes.bool,
  agencyId: PropTypes.number,
};
