import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";

import {
  addSale,
  closeConfirmModal,
  deleteCurrentSale,
  openConfirmModal,
} from "actions";

import SaleModalComponent from "components/SaleModal";

import { SALE_VALIDATION_ERRORS } from "constants/sale";

import { getDefaultPropertyId } from "lib/properties";
import { validateSale } from "lib/sale";
import toast from "lib/toast";

import {
  getAvailableSaleyards,
  getIsLivestockAgent,
  getIsSaleyardAdmin,
  getIsScaleOperator,
  getSales,
  getSelectedSaleyard,
  selectPropertyEnrichedBusinessByBusinessIdLookup,
} from "selectors";

// Transforms a saleyard from a state object into an id and name pair for a dropdown list
function saleyardAdapter({ saleyard_id, name }) {
  return {
    value: saleyard_id,
    label: name,
  };
}

// Transforms a business from a state object into an id and name pair for a dropdown list
// this also contains all of the associated PICs
function buyerAdapter(business) {
  return {
    ...business,
    id: business.id,
  };
}

function mapStateToProps(state, props) {
  const allSales = getSales(state);

  const isScaleOperator = getIsScaleOperator(state);
  const isSaleyardAdmin = getIsSaleyardAdmin(state);
  const isLivestockAgent = getIsLivestockAgent(state);
  // Try to pull the saleyard out of the URL, but if we can't, just use
  // the first available to the user.
  const availableSaleyards = getAvailableSaleyards(state);
  const selectedSaleyard = getSelectedSaleyard(state, props);
  const currentSaleyard = isEmpty(selectedSaleyard)
    ? availableSaleyards[0]
    : selectedSaleyard;
  const buyers = Object.values(
    selectPropertyEnrichedBusinessByBusinessIdLookup(state, props),
  ).map(buyerAdapter);

  const initialValues = {};
  const { saleId } = props;

  let saleyards = null;
  let hasMultiDeploymentAccess = true;

  if (isScaleOperator) {
    const { last_buyer_id, is_paid_user } = state.auth.activeRole;
    // get the prefill data for the "Default Buyer" field in the form
    const defaultBuyer = buyers.find(buyer => buyer.id === last_buyer_id);
    if (currentSaleyard && defaultBuyer) {
      const propertyId = getDefaultPropertyId(
        defaultBuyer,
        currentSaleyard.saleyard_id,
      );
      if (propertyId) {
        // Represents a buyer and property pair attached to a sale in the state
        initialValues.default_buyer_id = defaultBuyer.id;
        initialValues.default_property_id = propertyId;
      }
    }
    hasMultiDeploymentAccess = is_paid_user;
  }

  // By default, make the form select the currently active saleyard
  // but only when we are in new sale mode, and when the dispatched
  // action didn't contain any prefill data relating to the saleyard.
  // Only apply if the selected saleyard is "available", ie the currently
  // selected sale is a saleyard auction.
  if (
    currentSaleyard &&
    !saleId &&
    !initialValues.saleyard_id &&
    availableSaleyards.includes(currentSaleyard)
  ) {
    initialValues.saleyard_id = currentSaleyard.saleyard_id;
  }

  if (hasMultiDeploymentAccess) {
    saleyards = availableSaleyards.map(saleyardAdapter);
  } else {
    // free scale operator users should only have access to one deployment.
    // Use the current saleyard instead of the auth.scale_operator's
    // preferred saleyard to allow support accounts (user@bc.test) to view,
    // edit and create saleyards as they have multiple saleyards
    saleyards = [saleyardAdapter(currentSaleyard)];
  }

  // helper method used to identify if there are any duplicate sales on a specific day at a specific location
  const validateSaleBeforeCommit = (sale, isEditMode) => {
    const validationResults = validateSale(
      sale,
      Object.values(allSales),
      isSaleyardAdmin,
      isLivestockAgent,
      isEditMode,
    );

    if (validationResults.indexOf(SALE_VALIDATION_ERRORS.DUPLICATE_SALE) > -1) {
      toast.error(
        "A sale with those details has already been created. You may only create one sale per location, per day.",
      );
    }
    return validationResults.length === 0;
  };

  return {
    saleyards,
    saleId,
    initialValues,
    isScaleOperator,
    buyers,
    validateSale: validateSaleBeforeCommit,
  };
}

const mapDispatchToProps = {
  openConfirmModal,
  closeConfirmModal,
  deleteCurrentSale,
  addSale,
  handleDelete: deleteCurrentSale,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleModalComponent);
