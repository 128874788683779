import { addressSerializeMap, serializeAddress } from "lib/serializers/address";
import { integrationBusinessSerializeMap } from "lib/serializers/integrationBusinesses";
import { serializeProperty } from "lib/serializers/properties";
import { baseVendorCommissionBandSerializeMap } from "lib/serializers/vendorCommissionBands";
import { vendorSplitSerializerMap } from "lib/serializers/vendorSplits";
import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

import {
  bankingDetailsSerializeMap,
  serializeBankingDetails,
} from "./bankingDetails";
import { baseInterestSettingsSerializeMap } from "./interest";

const businessRelationMap = {
  relationType: "relation_type",
  relatedToId: "related_to_id",
  percentage: "percentage",
};
export const deserializeBusinessRelation =
  buildDeserializer(businessRelationMap);

export const serializeBusinessRelation = buildSerializer(businessRelationMap);

const businessUserSerializeMap = {
  email: "email",
  firstName: "first_name",
  lastName: "last_name",
  phoneNumber: "phone_number",
  isActive: "is_active",
  isAccountsRecipient: "is_accounts_recipient",
  isReportRecipient: "is_report_recipient",
  isComplianceUser: "is_compliance_user",
  isCommercialUser: "is_commercial_user",
  saleYards: "sale_yards",
};

const businessEmailRecipientSerializeMap = {
  id: "id",
  email: "email",
  firstName: "first_name",
  lastName: "last_name",
  phoneNumber: "phone_number",
  isAccountsRecipient: "is_accounts_recipient",
  isComplianceReportRecipient: "is_compliance_report_recipient",
  isCommercialReportRecipient: "is_commercial_report_recipient",
  isEnvdRecipient: "is_envd_recipient",
};

const serializeBusinessEmailRecipient = buildSerializer(
  businessEmailRecipientSerializeMap,
  "businessEmailRecipient",
);

const alternativesSerializeMap = {
  address: addressSerializeMap,
  hasDebtorInsurance: "has_debtor_insurance",
  hasTransitInsurance: "has_transit_insurance",
  isActive: "is_active",
  isTopBuyerSheep: "is_top_buyer_sheep",
  isTopBuyerCattle: "is_top_buyer_cattle",
  publicDisplayName: "public_display_name",
  shortCode: "short_code",
  shortCodeSaleyard: "short_code_saleyard",
  shortCodeAuctionsPlus: "short_code_auctions_plus",
  slug: "slug",
  source: "source",
  lastModified: "last_modified",
};

// This is kind of a bastardized serializeBusiness, which only works after running through this by virtue of
// ...rest being pushed back into the serialized version.
export const cleanBusinessPayload = (business, forMapImport = false) => {
  const payload = {
    name: business.name,
    notes: business.notes,
    is_active: business.is_active,
    is_top_buyer_sheep: business.isTopBuyerSheep,
    is_top_buyer_cattle: business.isTopBuyerCattle,
    properties: business.properties.map(serializeProperty),
    buyer_ways: business.buyer_ways,
    short_code: business.short_code,
    short_code_saleyard: business.short_code_saleyard,
    short_code_auctions_plus: business.shortCodeAuctionsPlus,
    slug: business.slug,
    public_display_name: business.publicDisplayName,
    abr_last_checked: business.abr_last_checked,
    agency_name: business.agency_name,
    agency_id: business.agencyId,
    business_pk: business.businessPk,
    is_vendor: business.isVendor,
    is_transporter: business.isTransporter,
    is_published: business.isPublished,
    is_buyer: business.isBuyer,
    is_hobby_farmer: business.isHobbyFarmer,
    is_meat_processor_commercial_buyer: business.isMeatProcessorCommercialBuyer,
    has_transit_insurance: business.hasTransitInsurance,
    commission_offset: business.commissionOffset,
    override_interest_settings: business.overrideInterestSettings,
    has_debtor_insurance: business.hasDebtorInsurance,
    trading_name: business.tradingName,
    email_recipients: business.emailRecipients.map(er =>
      serializeBusinessEmailRecipient(er),
    ),
    myob_customer_card_id: business.myobCustomerCardId,
    myob_supplier_card_id: business.myobSupplierCardId,
  };
  if (business.abn || !forMapImport) {
    payload.abn = business.abn;
  }
  if (!business.abn && !forMapImport) {
    payload.abn = null;
  }
  if (business.acn || !forMapImport) {
    payload.acn = business.acn;
  }
  if (business.bankingDetails || !forMapImport) {
    payload.banking_details = serializeBankingDetails(business.bankingDetails);
  }
  if (business.default_consigning_deployment_id || !forMapImport) {
    payload.default_consigning_deployment_id =
      business.defaultConsigningDeploymentId;
  }
  if (business.relationships.length > 0 || !forMapImport) {
    payload.relationships = business.relationships.map(br =>
      serializeBusinessRelation(br),
    );
  }

  if (business.address !== undefined) {
    payload.address = serializeAddress(business.address);
  }
  if (business.business_id) {
    payload.id = business.business_id;
    payload.business_id = business.business_id;
  }
  if (business.branch_id) {
    payload.branch_id = business.branch_id;
  }
  if (business.override_interest_settings) {
    payload.override_interest_settings = business.override_interest_settings;
  }
  return payload;
};

const sourceSerializeMap = {
  id: "id",
  type: "type",
};

const alternativesBusinessPropertySerializeMap = {
  address: addressSerializeMap,
  id: "id",
  isShown: "is_shown",
  name: "name",
  source: sourceSerializeMap,
};

const businessPropertySerializeMap = {
  alternatives: alternativesBusinessPropertySerializeMap,
  id: "id",
  isShown: "is_shown",
  name: "name",
  source: sourceSerializeMap,
  address: addressSerializeMap,
  // These are WRITE ONLY fields...  ideally we would REMOVE them on read, as they will always be blank.
  accreditationEu: "accreditation_eu",
  accreditationJbas: "accreditation_jbas",
  accreditationNee: "accreditation_nee",
  accreditationPcas: "accreditation_pcas",
  district: "district",
  lpaNumber: "lpa_number",
  msaNumber: "msa_number",
  defaultForSaleyardIds: "default_for_saleyard_ids",
};

const buyerWaySerializeMap = {
  destinationPropertyId: "destination_property_id",
  destinationPropertyPic: "destination_property_PIC",
  id: "id",
  isShown: "is_shown",
  name: "name",
  saleyardIds: "saleyard_ids",
  source: sourceSerializeMap,
};

const businessV2SerializeMap = {
  abn: "abn",
  abrLastChecked: "abr_last_checked",
  addedInLivestockSaleId: "added_in_livestock_sale_id",
  address: addressSerializeMap,
  agencyId: "agency_id",
  alternatives: alternativesSerializeMap,
  bankingDetails: bankingDetailsSerializeMap,
  bpayCustomerReferenceNumber: "bpay_customer_reference_number",
  branchId: "branch_id",
  brands: "brands",
  businessDeploymentId: "business_deployment_id",
  businessLivestockAgentId: "business_livestock_agent_id",
  businessUsers: businessUserSerializeMap,
  buyerWays: {
    alternatives: buyerWaySerializeMap,
    ...buyerWaySerializeMap,
  },
  created: "created",
  commissionOffset: "commission_offset",
  myobCustomerCardId: "myob_customer_card_id",
  defaultConsigningDeploymentId: "default_consigning_deployment_id",
  defaultDestinations: {
    propertyId: "property_id",
    saleyardId: "saleyard_id",
  },
  deploymentBusinessId: "deployment_business_id",
  emailRecipients: businessEmailRecipientSerializeMap,
  generateBpayCrn: "generate_bpay_crn",
  hasDebtorInsurance: "has_debtor_insurance",
  hasTransitInsurance: "has_transit_insurance",
  id: "id",
  integrationBusinesses: integrationBusinessSerializeMap,
  interestSettings: baseInterestSettingsSerializeMap,
  overrideInterestSettings: "override_interest_settings",
  isActive: "is_active",
  isBuyer: "is_buyer",
  isGSTRegistered: "is_gst_registered",
  isHobbyFarmer: "is_hobby_farmer",
  isMeatProcessorCommercialBuyer: "is_meat_processor_commercial_buyer",
  isPublished: "is_published",
  isTemporary: "is_temporary",
  isTopBuyerCattle: "is_top_buyer_cattle",
  isTopBuyerSheep: "is_top_buyer_sheep",
  isTransporter: "is_transporter",
  isVendor: "is_vendor",
  lastModified: "last_modified",
  lastReviewed: "last_reviewed",
  name: "name",
  notes: "notes",
  pk: "pk",
  primaryContactEmail: "primary_contact_email",
  properties: businessPropertySerializeMap,
  publicDisplayName: "public_display_name",
  relationships: businessRelationMap,
  shortCode: "short_code",
  shortCodeAuctionsPlus: "short_code_auctions_plus",
  shortCodeSaleyard: "short_code_saleyard",
  shouldChargeGSTOverride: "should_charge_gst_override",
  slug: "slug",
  myobSupplierCardId: "myob_supplier_card_id",
  tradingName: "trading_name",
  vendorCommissionBands: baseVendorCommissionBandSerializeMap,
  defaultVendorSplit: vendorSplitSerializerMap,
};

const nestedBusinessV2SerializeMap = {
  address: "address",
  banking_details: "bankingDetails",
  business_users: "businessUsers",
  buyer_ways: "buyerWays",
  default_destinations: "defaultDestinations",
  default_vendor_split: "defaultVendorSplit",
  email_recipients: "emailRecipients",
  integration_businesses: "integrationBusinesses",
  interest_settings: "interestSettings",
  relationships: "relationships",
  vendor_commission_bands: "vendorCommissionBands",
};

export const serializeBusiness = buildSerializer(
  businessV2SerializeMap,
  "businessV2",
  nestedBusinessV2SerializeMap,
);

export const deserializeBusiness = buildDeserializer(
  businessV2SerializeMap,
  "businessV2",
  nestedBusinessV2SerializeMap,
  {
    name: null,
    properties: [],
    buyerWays: [],
    isActive: true,
    emailRecipients: [],
    address: null,
  },
);

const globalBusinessesSerializerMap = {
  id: "id",
  name: "name",
};

export const serializeGlobalBusinesses = buildSerializer(
  globalBusinessesSerializerMap,
  "globalBusinesses",
);

export const deserializeGlobalBusinesses = buildDeserializer(
  globalBusinessesSerializerMap,
  "globalBusinesses",
);
