import queryString from "query-string";

import {
  BILLING_DOCUMENT,
  CONTACT_BILLING_DOCUMENT,
} from "constants/actionTypes";

import {
  serializeBillingDocument,
  serializeRetainedProceedsBillingDocument,
} from "lib/serializers/billingDocuments";

import {
  offlineActionCreator,
  offlineSubActionCreator,
  urlCheckAndAddIdAndAction,
} from "./lib";

export const billingDocumentUrlCreator = ({
  action,
  billingRunId,
  billingDocumentIds,
  changesSince,
  id,
  userRole,
  extraQueryParams = {},
} = {}) =>
  queryString.stringifyUrl(
    {
      url: urlCheckAndAddIdAndAction("/v2/billing-documents/", id, action),
      query: {
        ...extraQueryParams,
        changesSince,
        billing_run__cbid: billingRunId,
        cbid__in: billingDocumentIds,
        user_role: userRole,
      },
    },
    { arrayFormat: "comma" },
  );

export const contactBillingDocumentUrlCreator = ({
  contactBusinessId,
  userRole,
  statuses,
  changesSince,
} = {}) =>
  queryString.stringifyUrl({
    url: "/v2/billing-documents/",
    query: {
      business__cbid: contactBusinessId,
      user_role: userRole,
      status__in: statuses,
      changesSince,
    },
  });

export const BillingDocumentAction = offlineActionCreator(
  BILLING_DOCUMENT,
  serializeBillingDocument,
  billingDocumentUrlCreator,
  false,
);

BillingDocumentAction.email = offlineSubActionCreator(
  BILLING_DOCUMENT.EMAIL,
  () => "/v2/billing-documents/email/",
);

BillingDocumentAction.acceptChanges = offlineSubActionCreator(
  BILLING_DOCUMENT.ACCEPT_CHANGES,
  ({ id }) => `/v2/billing-documents/${id}/accept-changes/`,
);

BillingDocumentAction.acceptAndReverse = offlineSubActionCreator(
  BILLING_DOCUMENT.ACCEPT_AND_REVERSE,
  ({ id }) => `/v2/billing-documents/${id}/reverse/`,
);

BillingDocumentAction.rejectChanges = offlineSubActionCreator(
  BILLING_DOCUMENT.REJECT_CHANGES,
  ({ id }) => `/v2/billing-documents/${id}/reject-changes/`,
);

BillingDocumentAction.retainProceeds = offlineSubActionCreator(
  BILLING_DOCUMENT.RETAIN_PROCEEDS,
  ({ id }) => `/v2/billing-documents/${id}/retain-proceeds/`,
  "POST",
  serializeRetainedProceedsBillingDocument,
);

BillingDocumentAction.exportXeroBillCsv = billingRunId => {
  return {
    type: BILLING_DOCUMENT.FETCH_XERO_BILL_CSV.ACTION,
    billingRunId,
  };
};

BillingDocumentAction.exportXeroInvoiceCsv = billingRunId => {
  return {
    type: BILLING_DOCUMENT.FETCH_XERO_INVOICE_CSV.ACTION,
    billingRunId,
  };
};
BillingDocumentAction.exportXeroInvoiceApi = billingDocumentIds =>
  BillingDocumentAction.action(
    BILLING_DOCUMENT.EXPORT_XERO_INVOICE,
    offlineSubActionCreator(
      BILLING_DOCUMENT.EXPORT_XERO_INVOICE,
      () => "/v2/billing-documents/export-xero-invoices/",
    ),
    { billingDocumentIds },
  );

BillingDocumentAction.exportMyobCustomerTsv = billingRunId => {
  return {
    type: BILLING_DOCUMENT.FETCH_MYOB_CUSTOMER_EXPORT.ACTION,
    billingRunId,
  };
};

BillingDocumentAction.exportMyobSupplierTsv = billingRunId => {
  return {
    type: BILLING_DOCUMENT.FETCH_MYOB_SUPPLIER_EXPORT.ACTION,
    billingRunId,
  };
};

BillingDocumentAction.exportMyobSales = (billingRunId, billingDocumentIds) => {
  return {
    type: BILLING_DOCUMENT.FETCH_MYOB_SALES_EXPORT.ACTION,
    billingRunId,
    billingDocumentIds,
  };
};

BillingDocumentAction.exportMyobPurchases = (
  billingRunId,
  billingDocumentIds,
) => {
  return {
    type: BILLING_DOCUMENT.FETCH_MYOB_PURCHASES_EXPORT.ACTION,
    billingRunId,
    billingDocumentIds,
  };
};

BillingDocumentAction.exportMyobClassicSales = (
  billingRunId,
  billingDocumentIds,
) => {
  return {
    type: BILLING_DOCUMENT.FETCH_MYOB_CLASSIC_SALES_EXPORT.ACTION,
    billingRunId,
    billingDocumentIds,
  };
};

BillingDocumentAction.exportMyobClassicPurchases = (
  billingRunId,
  billingDocumentIds,
) => {
  return {
    type: BILLING_DOCUMENT.FETCH_MYOB_CLASSIC_PURCHASES_EXPORT.ACTION,
    billingRunId,
    billingDocumentIds,
  };
};

BillingDocumentAction.exportSaleyardBulkBillingXml = billingRunId => {
  return {
    type: BILLING_DOCUMENT.FETCH_SALEYARD_BULK_BILLING_XML.ACTION,
    billingRunId,
  };
};

BillingDocumentAction.exportAgentBulkBillingXml = billingRunId => {
  return {
    type: BILLING_DOCUMENT.FETCH_AGENT_BULK_BILLING_XML.ACTION,
    billingRunId,
  };
};

export const ContactBillingDocumentAction = offlineActionCreator(
  CONTACT_BILLING_DOCUMENT,
  serializeBillingDocument,
  contactBillingDocumentUrlCreator,
  false,
);
