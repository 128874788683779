import React, { useState } from "react";

import {
  faChevronDown,
  faChevronUp,
  faPrint,
  faTruck,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import {
  faPlus,
  faSign,
  faTimes,
  faWarehouseAlt,
} from "@fortawesome/pro-light-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { sortBy, uniq, uniqBy } from "lodash";
import { intersection } from "lodash/array";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  AuctionPenAction,
  bulkUpdateSaleLotsSerializer,
  BusinessAction,
  patchSaleLot,
  printBuyerWayPickList,
} from "actions";

import { AddPICModal } from "components/BusinessPICSelector/AddPICModal";
import { MultiButton } from "components/Button";
import NestedCard from "components/Card/NestedCard";
import { DeliveryPenChip, PICChip } from "components/Chip";
import { PlainCollapse } from "components/Collapse";
import { UnresolvedCommentIcon } from "components/Comments/Icon";
import DiffBadge from "components/DiffBadge/DiffBadge";
import PreventPropagation from "components/Events/PreventPropagation";
import { RightActionColumn } from "components/GroupedCard";
import { Column, Row } from "components/Layout";
import { LotCardBySaleLotId } from "components/LotCard/LotCard";
import { ResponsiveText } from "components/ResponsiveText";
import { BoldText, StatusText, Subtitle } from "components/Text";

import { AlternativeType } from "constants/businesses";
import { SaleLotPermissions } from "constants/permissions";
import { ReportDriver } from "constants/reports";
import { cards, saleLotStatuses } from "constants/sale";
import { Species } from "constants/species";

import { ForEveryoneExceptSaleWatcher } from "containers/ForUserType";

import {
  calculateAveragePriceAndFormatFromRawSaleLots,
  calculateAverageWeightAndFormatFromRawSaleLots,
} from "lib";

import { getCompareSaleLots } from "lib/auctionPens";
import { caselessCompare } from "lib/compare";
import { applyFilters, saleLotFilterOptions } from "lib/filters";
import {
  getLivestockSaleId,
  getSaleyardName,
  openDeliveryPenModal,
  openUpdateBuyerModal,
} from "lib/navigation";
import { hasPermission } from "lib/permissions";
import { getDefaultPropertyId } from "lib/properties";
import { getReportIcon, getReportUrl } from "lib/reports";
import {
  getCombinedLotNumber,
  getDeliverPatchInfo,
  getProgenyDisplayCount,
} from "lib/saleLot";

import {
  getActiveRole,
  getAuctionPens,
  getBidders,
  getBusinessById,
  getBuyerAndBuyerWayIdsByBuyerHash,
  getCurrentSale,
  getCurrentSaleyardId,
  getCurrentSpeciesId,
  getFavouritedOrFlaggedReportConfigsByDriver,
  getHasWriteAccessInCurrentSale,
  getIsBusinessUser,
  getProperties,
  getPropertyEnrichedBusinessByBusinessId,
  getSaleLotIdsByThirdPartyAndBuyerWayLookup,
  getSaleLots,
  getStatusForListOfStatuses,
  getUnresolvedSaleLotCommentCountByBuyerHash,
  selectAgencyByConsignmentIdLookup,
  selectAgencyIdBySaleLotId,
  selectAuctionPenIdBySaleLotIdLookup,
  selectBidderIdBySaleLotIdLookup,
  selectExceptionsBySaleLotIdLookup,
  selectIsWeighedBySaleLotIdLookup,
  selectStatusBySaleLotIdLookup,
} from "selectors";

import { calculateBuyerGridProps } from "./buyerGridPropsCalculator";

const ProgenyCount = styled.span`
  display: inline;
  white-space: nowrap;
  margin-right: ${({ theme }) => theme.space[1]}px;
  font-size: ${({ theme }) => theme.fontSizes.alpha}px;
`;

const CollapseIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => ` 
  font-size: ${theme.fontSizes.delta}px;
  color: ${theme.colors.gray40};
  cursor: pointer;
  `};
`;

const BuyerWayCard = props => {
  const {
    agencyId,
    basePath,
    showCommercialData,
    buyerHash,
    thirdPartyId = null,
    showBuyerData = false,
    saleLotIdFilter,
    expectedDeliveryPenId,
    filterValues,
  } = props;

  const isUsingRegisteredBidders =
    useSelector(getCurrentSale)?.using_registered_bidders;

  const roleSlug = useSelector(state => getActiveRole(state).slug);

  const isBusinessUser = useSelector(getIsBusinessUser);

  const favouriteBidderReports = useSelector(
    getFavouritedOrFlaggedReportConfigsByDriver(ReportDriver.BIDDER),
  );

  const favouriteBuyerReports = useSelector(
    getFavouritedOrFlaggedReportConfigsByDriver(ReportDriver.BUYER),
  );

  const sortedBuyerReports = sortBy(favouriteBuyerReports, "title");

  const { buyerId, buyerWayName, buyerWayId } = useSelector(
    getBuyerAndBuyerWayIdsByBuyerHash(buyerHash),
  );

  const speciesId = useSelector(getCurrentSpeciesId);
  const allSaleLots = useSelector(getSaleLots);
  const allProperties = useSelector(getProperties);
  const agencyIdBySaleLotId = useSelector(selectAgencyIdBySaleLotId);

  const statusBySaleLotIdLookup = useSelector(selectStatusBySaleLotIdLookup);
  const buyerWaySaleLotIds = useSelector(
    getSaleLotIdsByThirdPartyAndBuyerWayLookup(thirdPartyId, buyerHash),
  );

  const saleLotIds = Array.isArray(saleLotIdFilter)
    ? intersection(saleLotIdFilter, buyerWaySaleLotIds)
    : buyerWaySaleLotIds;

  const auctionPenIdBySaleLotIdLookup = useSelector(
    selectAuctionPenIdBySaleLotIdLookup,
  );
  const auctionPens = useSelector(getAuctionPens);
  const thirdParty = useSelector(getBusinessById(thirdPartyId)) || null;

  const unresolvedSaleLotCommentCount = useSelector(
    getUnresolvedSaleLotCommentCountByBuyerHash(buyerHash),
  );

  const buyer = useSelector(getPropertyEnrichedBusinessByBusinessId(buyerId));
  const exceptionsBySaleLotIdLookup = useSelector(
    selectExceptionsBySaleLotIdLookup,
  );
  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const isWeighedBySaleLotIdLookup = useSelector(
    selectIsWeighedBySaleLotIdLookup,
  );

  const bidderBySaleLotId = useSelector(selectBidderIdBySaleLotIdLookup);
  const bidders = useSelector(getBidders);

  const saleyardId = useSelector(getCurrentSaleyardId);

  const saleLots = saleLotIds
    .map(saleLotId => {
      allSaleLots[saleLotId].isWeighed = isWeighedBySaleLotIdLookup[saleLotId];
      return allSaleLots[saleLotId];
    })
    .filter(
      saleLot =>
        (!agencyId || agencyIdBySaleLotId[saleLot.id] === agencyId) &&
        (!expectedDeliveryPenId ||
          saleLot.deliveryPenId === expectedDeliveryPenId),
    );

  const saleLotBidders = uniq(
    saleLots.map(saleLot => bidderBySaleLotId[saleLot.id]).filter(Boolean),
  );

  const bidderText =
    saleLotBidders.length > 0
      ? `- ${saleLotBidders
          .map(bidderId => bidders[bidderId].registrationNumber)
          .join(", ")}`
      : null;

  const saleLotPriceUnitsAreEqual =
    uniq(saleLots.map(sl => sl.pricing_type_id)).length === 1;

  const {
    quantity,
    quantityDelivered,
    quantityProgeny,
    PICs,
    statuses,
    hasExceptions,
  } = saleLots.reduce(
    (acc, saleLot) => {
      acc.quantity += saleLot.quantity;
      acc.quantityDelivered += saleLot.quantity_delivered;
      acc.quantityProgeny += saleLot.quantityProgeny;
      const PIC = allProperties[saleLot.destination_property_id]?.PIC;
      if (PIC) {
        acc.PICs.add(PIC);
      }
      acc.statuses.push(statusBySaleLotIdLookup[saleLot.id]);
      if (exceptionsBySaleLotIdLookup[saleLot.id].length > 0) {
        acc.hasExceptions = true;
      }
      return acc;
    },
    {
      quantity: 0,
      quantityDelivered: 0,
      quantityProgeny: 0,
      PICs: new Set(),
      statuses: [],
      hasExceptions: false,
    },
  );

  const status = getStatusForListOfStatuses(statuses);

  const [isOpen, setIsOpen] = useState(false);
  const [showAddPIC, setShowAddPIC] = useState(false);
  const collapseCard = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();

  let filteredSaleLots = saleLots;

  if (filterValues) {
    filteredSaleLots = applyFilters(
      saleLotFilterOptions,
      filterValues,
      filteredSaleLots,
    );
  }

  const sortedSaleLots = filteredSaleLots.map(saleLot => ({
    ...saleLot,
    startPen: auctionPens[auctionPenIdBySaleLotIdLookup[saleLot.id]]?.start_pen,
    lotNumber: getCombinedLotNumber(saleLot),
  }));
  sortedSaleLots.sort(getCompareSaleLots("startPen"));

  const averagePrice =
    saleLots.length > 0
      ? calculateAveragePriceAndFormatFromRawSaleLots(saleLots)
      : "-";
  const averageWeight =
    saleLots.length > 0
      ? calculateAverageWeightAndFormatFromRawSaleLots(saleLots)
      : "-";

  const [gridItemProps, gridBuyerTextProps] =
    calculateBuyerGridProps(speciesId);

  const deliver = () => {
    const bulkPayload = saleLots.map(sl => ({
      id: sl.id,
      ...getDeliverPatchInfo(sl, sl.buyer, sl.saleyardId),
    }));

    dispatch(
      bulkUpdateSaleLotsSerializer(bulkPayload, {
        actionText: "delivered",
        changeReason: "Bulk delivered by buyer way",
      }),
    );
  };
  const unDeliver = () => {
    const bulkPayload = saleLots.map(sl => ({
      id: sl.id,
      quantity_delivered: null,
    }));
    dispatch(
      bulkUpdateSaleLotsSerializer(bulkPayload, {
        actionText: "un-delivered",
        changeReason: "Bulk un-delivered by buyer way",
      }),
    );
  };

  const setDeliveryPen = () => openDeliveryPenModal(saleLots.map(s => s.id));

  const setProperty = propertyId => {
    saleLots.map(sl =>
      dispatch(
        patchSaleLot(
          { destination_property_id: propertyId, id: sl.id },
          {
            actionText: "updated destination property",
            changeReason: "Bulk updated destination property by buyer way",
            promptSetDefaultDestinationProperty: true,
          },
        ),
      ),
    );

    if (buyerId && buyerWayName) {
      dispatch(
        BusinessAction.suggestBuyerWayProperty(
          buyerId,
          buyerWayName,
          propertyId,
        ),
      );
    }
  };

  const setBuyer = () => {
    // Being on a buyer way, all of these should match.

    // Find the first lot that has a invoice to business and set that as the default.
    const invoiceToBusinessId = saleLots.find(
      s => s.invoiceToBusinessId,
    )?.invoiceToBusinessId;

    openUpdateBuyerModal(
      buyerId,
      saleLots[0].destination_property_id,
      buyerWayId,
      thirdPartyId,
      invoiceToBusinessId,
    );
  };

  const hasBulkUpdatePermission = saleLots.every(s =>
    hasPermission(s, SaleLotPermissions.update),
  );

  const buttons = [];
  const reportButtons = [];

  if (saleLots.length > 0) {
    // if any sold sale lots do not have a PIC.
    const anyWithoutPIC = saleLots.some(
      sl => sl.buyer_id && !sl.destination_property_id,
    );

    const defaultPropertyId = getDefaultPropertyId(
      buyer,
      saleyardId,
      buyerWayName,
    );

    const hasBulkUpdatePermission = saleLots.every(s =>
      hasPermission(s, SaleLotPermissions.update),
    );

    if (!isBusinessUser) {
      const propertyButtonSettings = prop => {
        // fallback to using the SY property name
        //  - when Deployment Property doesn't have a name
        let saleyardName = "";
        if (prop.source?.type === AlternativeType.Deployment) {
          saleyardName =
            prop.alternatives
              ?.filter(
                alternative =>
                  alternative.name &&
                  alternative.source?.type === AlternativeType.Saleyard,
              )
              .reduce((alternateName, alternative) => {
                return alternateName || alternative.name;
              }, "") || "";
        }
        const propertyName = prop.name || saleyardName;
        return {
          title: `Set ${prop.PIC} ${
            propertyName && propertyName !== "" ? `(${propertyName})` : ``
          }`,
          titleBold: caselessCompare(propertyName, buyerWayName),
          onClick: () => {
            setProperty(prop.id);
          },
          default:
            anyWithoutPIC && defaultPropertyId && defaultPropertyId === prop.id,
          icon: faSign,
          isDisabled: !hasBulkUpdatePermission || !hasWriteAccessInCurrentSale,
        };
      };
      if (buyer.properties.length > 5) {
        buttons.push({
          title: "Select PIC",
          isDisabled: !hasBulkUpdatePermission || !hasWriteAccessInCurrentSale,
          icon: faSign,
          subNavItems: sortBy(buyer.properties, "PIC").map(prop => {
            return propertyButtonSettings(prop);
          }),
        });
      } else {
        sortBy(buyer.properties, "PIC").forEach(prop => {
          buttons.push(propertyButtonSettings(prop));
        });
      }

      buttons.push({
        title: `Add new PIC`,
        dataTour: "addNewPIC",
        icon: faPlus,
        onClick: () => {
          setShowAddPIC(true);
        },
        default: anyWithoutPIC && buyer.properties.length === 0,
        isDisabled: !hasBulkUpdatePermission || !hasWriteAccessInCurrentSale,
      });

      // We could check if we're connected to hub before adding this one, as it's needed
      // but, it's probably good to have there as a flag it exists.
      buttons.push({
        title: "Buyer Way Pick List",
        icon: faPrint,
        onClick: () => {
          dispatch(printBuyerWayPickList({ buyerWayName, saleLotIds }));
        },
      });

      // Deliver and unDeliver will be default if PICs are all setup.
      if (
        status === saleLotStatuses.SOLD ||
        status === saleLotStatuses.NO_SALE
      ) {
        buttons.push({
          title: "Deliver All",
          dataTour: "deliverAll",
          icon: faTruck,
          onClick: deliver,
          default: !anyWithoutPIC,
          isDisabled: !hasBulkUpdatePermission || !hasWriteAccessInCurrentSale,
        });
      } else if (status === saleLotStatuses.DELIVERED) {
        buttons.push({
          title: "Undeliver All",
          dataTour: "undeliverAll",
          icon: faUndo,
          onClick: unDeliver,
          default: !anyWithoutPIC,
          isDisabled: !hasBulkUpdatePermission || !hasWriteAccessInCurrentSale,
        });
      }

      buttons.push({
        title: "Set Delivery Pen",
        icon: faWarehouseAlt,
        onClick: setDeliveryPen,
        default: !anyWithoutPIC,
        isDisabled: !hasBulkUpdatePermission || !hasWriteAccessInCurrentSale,
      });

      if (
        [
          saleLotStatuses.SOLD,
          saleLotStatuses.DELIVERED,
          saleLotStatuses.PARTIALLY_DELIVERED,
        ].includes(status)
      ) {
        buttons.push({
          title: "Bulk Update Buyer",
          icon: faHandHoldingUsd,
          onClick: setBuyer,
          isDisabled: !hasBulkUpdatePermission,
        });
      }

      const unAssignedSoldSaleLotIds = saleLotIds.filter(
        saleLotId => !allSaleLots[saleLotId].deliveryPenId,
      );

      const setDeliveryPenOnUnassignedLots = () =>
        dispatch(
          bulkUpdateSaleLotsSerializer(
            unAssignedSoldSaleLotIds.map(id => ({
              id,
              deliveryPenId: expectedDeliveryPenId,
            })),
          ),
        );

      if (unAssignedSoldSaleLotIds.length > 0) {
        buttons.push({
          title: `Set Delivery Pen On ${unAssignedSoldSaleLotIds.length} Sold Lots`,
          icon: faWarehouseAlt,
          isDisabled: !hasBulkUpdatePermission || !hasWriteAccessInCurrentSale,
          onClick: setDeliveryPenOnUnassignedLots,
        });
      }
    }
  }

  if (expectedDeliveryPenId) {
    buttons.push({
      title: "Remove Owner",
      icon: faTimes,
      isDisabled: !hasBulkUpdatePermission,
      onClick: () => {
        dispatch(
          AuctionPenAction.penOwner.delete(expectedDeliveryPenId, {
            businessId: buyerId,
            buyerWay:
              buyerWayName && buyerWayId
                ? {
                    name: buyerWayName,
                    id: buyerWayId,
                  }
                : null,
          }),
        );
      },
    });
  }

  const agenciesByConsignmentId = useSelector(
    selectAgencyByConsignmentIdLookup,
  );

  const agencies = uniqBy(
    saleLots.map(lot => agenciesByConsignmentId[lot.consignment_id]),
    "id",
  );

  const createOpenReportButton = (title, extraArgs, report) => {
    reportButtons.push({
      title,
      icon: getReportIcon(report),
      onClick: () =>
        window.open(
          getReportUrl(report, {
            saleyardName: getSaleyardName(),
            livestockSaleId: getLivestockSaleId(),
            ...extraArgs,
          }),
          "_blank",
        ),
    });
  };
  if (isUsingRegisteredBidders) {
    saleLotBidders.map(bidderId =>
      favouriteBidderReports.map(report =>
        report.isAgencyDriven
          ? agencies.forEach(agency =>
              createOpenReportButton(
                `${agency.shortName} - ${report.title} (${bidders[bidderId].registrationNumber})`,
                {
                  bidderId,
                  userRole: roleSlug,
                  buyerId: buyer.pk,
                  agencyShortName: agency.shortName,
                  buyerWayId,
                },
                report,
              ),
            )
          : createOpenReportButton(
              `${report.title} (${bidders[bidderId].registrationNumber})`,
              { bidderId, userRole: roleSlug },
              report,
            ),
      ),
    );
  }

  //  we want to ignore the buyer way per page reports as it is irrelevant to individual buyer ways
  const buyerWayReports = uniqBy(sortedBuyerReports, "slug");

  buyerWayReports.forEach(report =>
    createOpenReportButton(
      `${report.title}`,
      {
        buyerId: buyer.pk,
        userRole: roleSlug,
        buyerWay: buyerWayId || null,
      },
      report,
    ),
  );

  if (isBusinessUser) {
    buttons.push(...reportButtons);
  } else {
    buttons.push({
      title: "Reports",
      subNavItems: reportButtons,
    });
  }

  const buyerData = showBuyerData ? (
    <>
      <Grid item xs={3}>
        <Subtitle>Third Party</Subtitle>
        <BoldText>{thirdParty?.name || "-"}</BoldText>
      </Grid>

      <Grid item xs={3}>
        <Subtitle>Buyer</Subtitle>
        <BoldText>{buyer.name || "-"}</BoldText>
      </Grid>
    </>
  ) : null;

  return (
    <NestedCard data-tour="buyerWayCard" status={status} slim>
      <Grid container alignItems="center" onClick={collapseCard}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Row flexGrow>
            <UnresolvedCommentIcon
              unresolvedCommentCount={unresolvedSaleLotCommentCount}
              horizontalPadding={1}
              size="2x"
            />
          </Row>
          <Row justifyEnd>
            <Row flexWrap>
              <DeliveryPenChip saleLotIds={saleLots.map(s => s.id)} />
              <PICChip businessId={buyerId} PICs={Array.from(PICs)} />
            </Row>
            <ForEveryoneExceptSaleWatcher>
              <Row>
                <PreventPropagation>
                  <MultiButton
                    preferEnabledAsDefault
                    buttons={buttons}
                    restrictHeightSubNav
                    actionsOnly={isBusinessUser}
                    includeSearchSubNavThreshold={7}
                    actionsOnlyLabel="Reports"
                  />
                </PreventPropagation>
              </Row>
            </ForEveryoneExceptSaleWatcher>
          </Row>
        </Grid>

        <Row full>
          <Column full>
            <Grid container>
              <Grid {...gridBuyerTextProps} container>
                {buyerData}

                <Grid
                  container
                  item
                  xs={showBuyerData ? 3 : 12}
                  direction="column"
                >
                  <Subtitle>
                    Buyer Way {bidderText}{" "}
                    {hasExceptions && <StatusText status="error" />}
                  </Subtitle>
                  <BoldText>{buyerWayName || "-"}</BoldText>
                </Grid>
              </Grid>

              <Grid {...gridItemProps}>
                <Subtitle>
                  <ResponsiveText
                    mobile="HC"
                    tablet="Head Count"
                    desktop="Head Count"
                  />
                </Subtitle>
                <BoldText>
                  {quantity}{" "}
                  {quantityProgeny > 0 && (
                    <ProgenyCount>
                      {getProgenyDisplayCount(quantityProgeny)}
                    </ProgenyCount>
                  )}
                </BoldText>
                <DiffBadge
                  quantityDelivered={quantityDelivered}
                  quantity={quantity}
                  quantityProgeny={quantityProgeny}
                />
              </Grid>
              <Grid {...gridItemProps}>
                <Subtitle>
                  <ResponsiveText
                    mobile="Del"
                    tablet="Delivered"
                    desktop="Delivered"
                  />
                </Subtitle>

                <BoldText>{quantityDelivered}</BoldText>
              </Grid>

              {speciesId === Species.CATTLE && (
                <Grid {...gridItemProps}>
                  <Subtitle>
                    <ResponsiveText
                      mobile="Avg. Wt."
                      tablet="Avg. Weight"
                      desktop="Avg. Weight"
                    />
                  </Subtitle>
                  <BoldText>{averageWeight}</BoldText>
                </Grid>
              )}

              {saleLotPriceUnitsAreEqual && (
                <Grid {...gridItemProps}>
                  <Subtitle>Avg. Price</Subtitle>
                  <BoldText>{averagePrice}</BoldText>
                </Grid>
              )}
            </Grid>
          </Column>
          {saleLots.length > 0 && (
            <RightActionColumn alignCenter justifyCenter>
              <CollapseIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </RightActionColumn>
          )}
        </Row>
      </Grid>

      <PreventPropagation>
        <AddPICModal
          show={showAddPIC}
          closeSelf={() => {
            setShowAddPIC(false);
          }}
          business={buyer}
          defaultPICName={buyerWayName || ""}
          handleAfterAdd={setProperty}
        />
      </PreventPropagation>
      <PlainCollapse isOpen={isOpen}>
        <PreventPropagation>
          {sortedSaleLots.map(saleLot => (
            <LotCardBySaleLotId
              key={saleLot.id}
              basePath={basePath}
              saleLotId={saleLot.id}
              cardType={cards.SALE_LOT_CARD}
              showActions
              showPen
              showVendor
              readOnly={!hasWriteAccessInCurrentSale}
              showCommercialData={showCommercialData}
              expectedDeliveryPenId={expectedDeliveryPenId}
            />
          ))}
        </PreventPropagation>
      </PlainCollapse>
    </NestedCard>
  );
};
BuyerWayCard.propTypes = {
  basePath: PropTypes.string,
  buyerWay: PropTypes.object,
  showCommercialData: PropTypes.bool,
};

export default BuyerWayCard;
