import React, { useMemo } from "react";

import Grid from "@material-ui/core/Grid";
import { addDays } from "date-fns";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

import { Warning } from "components/ErrorMessage";
import { BusinessField } from "components/Form/Fields";
import { HTMLTemplateTextField } from "components/Form/Fields/DetailsText";
import {
  DateInput,
  Input,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";

import { BillingNumberPrefixes } from "constants/billing";
import {
  BillingDocumentStatus,
  BillingDocumentStatusOptions,
  BillingDocumentType,
} from "constants/billingDocuments";

import {
  formatDateString,
  getDateFromISO8601DateString,
} from "lib/timeFormats";

import {
  getActiveLivestockAgentDeployment,
  getBillingDocumentById,
  getCurrentDeploymentSalesList,
  getCurrentSale,
  getSales,
} from "selectors";

export function BillingDocumentForm(props) {
  const {
    billingDocumentId,
    saleId = null,
    namespace: ns = "",
    prefillApplied = false,
  } = props;

  const { deploymentSettings } =
    useSelector(getActiveLivestockAgentDeployment) || {};

  const deploymentSale = useSelector(getCurrentDeploymentSalesList)?.[0] || {};
  const { buyer_terms: buyerTerms, vendor_terms: vendorTerms } = deploymentSale;
  let sale = useSelector(getCurrentSale);
  const sales = useSelector(getSales);
  if (saleId) {
    sale = sales[saleId];
  }
  const saleDate = sale.date;

  const billingDocument =
    useSelector(getBillingDocumentById(billingDocumentId)) || {};
  const { type: documentType } = billingDocument;

  const {
    values: { status: currentStatus, dueDate },
  } = useFormikContext();

  // Show a warning when the due date is different to the expected.
  const terms =
    (documentType === BillingDocumentType.RCTI ? vendorTerms : buyerTerms) || 0;

  // Convert to a date, add the terms, then back to a string for comparison.
  const expectedDueDate = formatDateString(
    addDays(getDateFromISO8601DateString(saleDate), terms),
  );

  const showDueDateWarning =
    dueDate &&
    expectedDueDate !== formatDateString(getDateFromISO8601DateString(dueDate));

  const prefix = useMemo(() => {
    if (documentType === BillingDocumentType.RCTI) {
      return (
        deploymentSettings?.accountSalePrefix || BillingNumberPrefixes.RCTI
      );
    } else {
      return deploymentSettings?.invoicePrefix || BillingNumberPrefixes.Invoice;
    }
  }, [documentType, deploymentSettings]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SelectField
          label="Status"
          name={withNamespace(ns, "status")}
          options={BillingDocumentStatusOptions}
          required
          menuPortalTarget={document.body}
        />
      </Grid>

      <Grid item xs={6}>
        <Input
          beforeSymbol={prefix}
          label="Document Number"
          name={withNamespace(ns, "number")}
          tooltip="The reference number assigned to this document.  This will be automatically populated when a document is approved."
          type="number"
          placeholder="Assigned on Approval"
          emptyValue={null}
        />
      </Grid>

      <Grid item xs={6}>
        <BusinessField name="recipientBusinessId" label="Recipient" disabled />
      </Grid>
      <Grid item xs={6}>
        <BusinessField name="issuerBusinessId" label="Issuer" disabled />
      </Grid>

      <Grid item xs={12}>
        <DateInput
          name="dueDate"
          label="Due Date"
          required
          disabled={currentStatus !== BillingDocumentStatus.DRAFT}
        />
        {showDueDateWarning ? (
          <Warning>
            Warning: The date entered is different to the default (
            {expectedDueDate}) for this document type.
          </Warning>
        ) : null}
      </Grid>
      <Grid item xs={12} md={12}>
        <HTMLTemplateTextField name="detailsText" />
        {prefillApplied ? (
          <Warning>
            The Details Text has been prefilled from your Agency Settings.
          </Warning>
        ) : null}
      </Grid>
    </Grid>
  );
}
