import React, { useMemo } from "react";

import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  matchPath,
  Redirect,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import styled from "styled-components/macro";

import { BillingRunAction } from "actions";

import SecurityTrimmedRoute from "components/Authorization/SecurityTrimmedRoute";
import { Export } from "components/BillingWorkFlow/Export";
import { Overview } from "components/BillingWorkFlow/Overview";
import { Review } from "components/BillingWorkFlow/Review";
import { Send } from "components/BillingWorkFlow/Send";
import { Sundries } from "components/BillingWorkFlow/Sundries";
import { MultiButton } from "components/Button";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import { ExtraSmallText } from "components/Text";

import { ApiModel } from "constants/loading";
import { DeploymentPermissions } from "constants/permissions";

import {
  getBillingSubPageUrl,
  getLivestockSaleId,
  getSaleRoute,
} from "lib/navigation";
import { formatDateTimeUTC } from "lib/timeFormats";

import {
  getActiveBillingRunId,
  getBillingRunById,
  getBillingRuns,
  getIsBillingRunCheckingForChanges,
  getLastActiveBillingRunId,
  selectRoleCurrentDeployments,
} from "selectors";

import appHistory from "appHistory";
import { useMountEffect } from "hooks";
import { useToggleFullScreen } from "hooks/useToggleFullscreen";
import { useWaityLoadyMultiButton } from "hooks/useWaityLoadyMultiButton";

const noBillingRunLabel = {
  disabled: true,
};

const changesDetectedLabel = {
  optional: (
    <Typography variant="caption" color="error">
      Changes Detected
    </Typography>
  ),
  error: true,
};

const steps = [
  {
    label: "Overview",
    path: "overview",
  },
  {
    label: "Sundries",
    path: "sundries",
  },
  {
    label: "Review",
    path: "review",
    getLabelProps: billingRun => {
      if (isEmpty(billingRun)) {
        return noBillingRunLabel;
      }
      if (billingRun?.hasChanges) {
        return changesDetectedLabel;
      }
    },
  },
  {
    label: "Send",
    path: "send",
    getLabelProps: billingRun => {
      if (isEmpty(billingRun)) {
        return noBillingRunLabel;
      }
    },
  },
  {
    label: "Export",
    path: "export",
    getLabelProps: billingRun => {
      if (isEmpty(billingRun)) {
        return noBillingRunLabel;
      }
    },
  },
];

const StyledStepper = styled(Stepper)(
  ({ theme }) => `
  border-bottom: 1px solid ${theme.colors.controlBorder};
`,
);

const Actions = styled.div(
  ({ theme }) => `
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
        border-bottom: 1px solid ${theme.colors.controlBorder};

        background: ${theme.colors.white};

  `,
);

export const BillingWorkFlow = () => {
  const dispatch = useDispatch();

  const billingPath = `${getSaleRoute(":saleyard", ":saleId")}/billing`;

  const billingRunId = useSelector(getActiveBillingRunId);
  const lastActiveBillingRunId = useSelector(getLastActiveBillingRunId);

  useMountEffect(() => {
    // If we move away from the page, we want to unsubscribe, but if we come back, we want to open the last open billing run.
    if (!billingRunId && lastActiveBillingRunId) {
      dispatch(BillingRunAction.subscribe(lastActiveBillingRunId));
    }

    return () => {
      dispatch(BillingRunAction.unsubscribe());
      dispatch(BillingRunAction.disable());
    };
  });

  const isBillingEnabled = useSelector(state => state.billingRuns.isEnabled);
  React.useEffect(() => {
    if (!isBillingEnabled) {
      dispatch(BillingRunAction.enable(getLivestockSaleId()));
    }
  }, [isBillingEnabled, dispatch]);

  const match = useRouteMatch();
  const location = useLocation();

  function changeTabs(newScreen) {
    appHistory.push(getBillingSubPageUrl(newScreen));
  }

  const openStep = index => {
    changeTabs(steps[index].path);
  };

  const activeStep = useMemo(() => {
    // use the same mechanism that react-router uses to match paths to determine the open section.
    const matchProfile = matchPath(location.pathname, {
      path: `${billingPath}/:section`,
    });
    const match = (matchProfile && matchProfile.params) || {};

    return steps.findIndex(step => step.path === match.section) || 0;
  }, [billingPath, location.pathname]);
  const [isFullScreen, toggleFullScreen] = useToggleFullScreen();

  const billingRun = useSelector(getBillingRunById(billingRunId));

  // We fetch data on page load, so to avoid the initial isloading flash if we already have data, override it.
  const billingRunsExist = useSelector(state => {
    const billingRuns = getBillingRuns(state);
    return Object.keys(billingRuns).length > 0;
  });

  const isBillingRunCheckingForChanges = useSelector(
    getIsBillingRunCheckingForChanges,
  );

  const buttons = [
    useWaityLoadyMultiButton(
      "Re-run",
      () => BillingRunAction.checkForChanges(billingRunId),
      isBillingRunCheckingForChanges,
      true,
      !billingRunsExist,
    ),
    {
      title: isFullScreen ? "Exit Fullscreen mode" : "Enter Fullscreen mode",
      icon: isFullScreen ? faCompressAlt : faExpandAlt,
      onClick: toggleFullScreen,
    },
  ];

  return (
    <WaitForSync
      requiredData={[
        ApiModel.BILLING_DATA,
        ApiModel.BILLING_DOCUMENTS,
        ApiModel.BILLING_RUNS,
        ApiModel.BUSINESSES,
        ApiModel.EMAIL,
        ApiModel.LEDGER_ENTRIES,
        ApiModel.MANUAL_ADJUSTMENTS,
      ]}
      forceNotLoading={billingRunsExist}
    >
      <Column full>
        <Row>
          <Column full>
            <StyledStepper nonLinear activeStep={activeStep}>
              {steps.map((step, index) => {
                const labelProps = step.getLabelProps?.(billingRun) || {};
                if (!labelProps.disabled) {
                  labelProps.onClick = () => openStep(index);
                }

                return (
                  <Step data-tour={step.path} key={step.label}>
                    <StepLabel {...labelProps}>{step.label}</StepLabel>
                  </Step>
                );
              })}
            </StyledStepper>
          </Column>
          <Actions>
            <Column alignCenter gridGap={1}>
              <MultiButton buttons={buttons} />
              <ExtraSmallText>
                {`Last Run: ${billingRun && billingRun.ledgerEntriesGeneratedDateTime ? formatDateTimeUTC(billingRun.ledgerEntriesGeneratedDateTime) : ""}`}
              </ExtraSmallText>
            </Column>
          </Actions>
        </Row>

        <Switch>
          <SecurityTrimmedRoute
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureBilling]}
            path={`${billingPath}/overview`}
            component={Overview}
          />
          <SecurityTrimmedRoute
            exact
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureManualCharges]}
            path={`${billingPath}/sundries`}
            component={Sundries}
          />
          <SecurityTrimmedRoute
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureBillingRun]}
            path={`${billingPath}/review`}
            component={Review}
          />
          <SecurityTrimmedRoute
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureBillingRun]}
            path={`${billingPath}/send`}
            component={Send}
          />

          <SecurityTrimmedRoute
            objectArraySelector={selectRoleCurrentDeployments}
            permissionRequired={[DeploymentPermissions.featureBillingRun]}
            path={`${billingPath}/export`}
            component={Export}
          />

          <Redirect to={`${match.url}/overview`} />
        </Switch>
      </Column>
    </WaitForSync>
  );
};
