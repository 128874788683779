import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

const reportJobSerializerMap = {
  documentStatus: "document_status",
  id: "id",
  status: "status",
  file: "file",
  report: "report",
};

const proceedsRetainedByBillingDocumentSerializerMap = {
  glCode: "gl_code",
  integrationStatus: "integration_status",
  number: "number",
  totalAmountCents: "total_amount_cents",
};

const serializeMap = {
  billingRunId: "billing_run_id",
  created: "created",
  dueDate: "due_date",
  detailsText: "details_text",
  emailIds: "email_ids",
  exportErrors: "export_errors",
  exportWarnings: "export_warnings",
  hasFinancialChanges: "has_financial_changes",
  hasCosmeticChanges: "has_cosmetic_changes",
  id: "id",
  integrationStatus: "integration_status",
  issuedDate: "issued_date",
  issuerBusinessId: "issuer_business_id",
  invoiceNumber: "invoice_number",
  latestInterestChargeDate: "latest_interest_charge_date",
  lastModified: "last_modified",
  livestockSaleIds: "livestock_sale_ids",
  number: "number",
  paidDate: "paid_date",
  paymentIds: "payment_ids",
  proceedsRetainedByBillingDocument:
    proceedsRetainedByBillingDocumentSerializerMap,
  recipientBusinessId: "recipient_business_id",
  reportJobs: reportJobSerializerMap,
  status: "status",
  totalAmountDollars: "total_amount_dollars",
  totalPaidCents: "total_paid_cents",
  totalTaxAmountDollars: "total_tax_amount_dollars",
  type: "type",
  xeroBrandingTheme: "xero_branding_theme",
  xeroInvoiceId: "xero_invoice_id",
};

const nestedObjectMap = {
  report_jobs: "reportJobs",
  proceeds_retained_by_billing_document: "proceedsRetainedByBillingDocument",
};

export const serializeBillingDocument = buildSerializer(
  serializeMap,
  "billingDocument",
  nestedObjectMap,
);

export const deserializeBillingDocument = buildDeserializer(
  serializeMap,
  "billingDocument",
  nestedObjectMap,
);

export const serializeRetainedProceedsBillingDocument = buildSerializer(
  proceedsRetainedByBillingDocumentSerializerMap,
  "billingDocument",
);

export const deserializeRetainedProceedsBillingDocument = buildDeserializer(
  proceedsRetainedByBillingDocumentSerializerMap,
  "retainedProceedsBillingDocument",
);

const bulkUpdateBillingDocumentSerializeMap = [
  // Must be a subset of the serializeMap, otherwise state will be incorrect.
  "dueDate",
  "status",
].reduce((acc, key) => {
  if (!serializeMap[key]) {
    throw new Error(`Key ${key} is missing from serializeMap`);
  }

  acc[key] = serializeMap[key];
  return acc;
}, {});

export const serializeBulkUpdateBillingDocument = buildSerializer(
  bulkUpdateBillingDocumentSerializeMap,
  "billingDocument",
);

export const deserializeBulkUpdateBillingDocument = buildDeserializer(
  bulkUpdateBillingDocumentSerializeMap,
  "billingDocument",
);
